

$module: 'card';

.#{$module} {
  	--background-color: none;
  	text-align: center;
  	display: grid;	
    grid-template-rows: max-content;
  	
  	/* To remove hard-set width on inherited a elements */
  	& > a.#{$module}-content, & > a.#{$module}-footer {
			width: 100%;		
	}
  	
  	&-content {
  		display: flex;
  		flex-direction: column;
		background-color: var(--background-color);
		color: black;
		font-weight: 500;
		
		&__text {
			font-size: 16px;
			margin: 0;
			padding: 0 1.75rem 0.5rem;
			min-height: 120px;	
			display: flex;
  			justify-content: flex-end;
  			flex-direction: column;
		}
		
		&__img {
			flex-basis: 120px;	
			display: flex;
			align-items: center;
  			justify-content: flex-end;
  			flex-direction: column;
		}
	}
  	
  	&-footer {
  		display: flex;
  		justify-content: space-between;
		color: black;
		font-weight: 500;
		position: relative;
		
		&:before {
			opacity: 0.6;
			background-color: var(--background-color);
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			height: 100%;
			width: 100%;
			z-index: -1;
		}
		
		&__text {
			width: 75%;
			margin: 0;
			padding: 1.25rem 1.5rem;
			text-align: left;
			line-height: 1;
		}
		
		&__img {
			padding: 0.5rem 1rem;
			max-width: 25%;
		}
	}
	
	&--lg {
		grid-column: span 2;
		background-color: var(--background-color);
  		padding: 2rem;
  		
  		@media(max-width: 600px) {
  			grid-column: span 1;
  		}
  	}
 
}