
//
// Editorial Macros
// --------------------------------------------------

//Columns
.editorColumns {
	width: 100%;
	@include clearfix;
}
.editorColumnsInner {
	float: left; 
}

.editorColumnsPadding{
	padding: em(5);
}




// RTE Images
// -------------------------

.editorImgFloat-left,
.editorLeftImage{
	float: left;
	width: auto;
	margin: 0 em($baseLineHeight) em($baseLineHeight) 0;
}
.editorImgFloat-right,
.editorRightImage{
	float: right;
	width: auto;
	margin: 0 0 em($baseLineHeight) em($baseLineHeight);
}
.editorImgFloat-center{
	margin: em($baseLineHeight) auto em($baseLineHeight);
	text-align: center;
	display: block;
}

.editorLeftImage, .editorRightImage {
	width: auto !important;
}

.editorCaption {
	font-size: 12px;
}

// Macro Images
// -------------------------

.imageBox{
	width: 100%;
	text-align: center;
	display: block; 
	clear: both;
	margin: em($baseLineHeight) 0;
	img{
		margin: 0 auto;
	}
}

.imageBoxCaption{
	display:block;
	margin:0 auto;
	font-size: 12px;
}

// Video
// -------------------------
.embed-container {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    max-width: 100%;
    height: auto;
    margin-top: em($baseLineHeight);
}

.embed-container iframe, 
.embed-container object, 
.embed-container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
small{
	font-size:0.8em
	}
	small p{
	font-size:10px
	}
	small ol{
	font-size:10px
	}
	
	sup{
		font-size:0.8em;
		vertical-align: super;
		}
	sub{
		font-size:0.8em;
		vertical-align: sub;
		}
		
.editorFullBreak{
	clear: both;
}


// Tables 

.rteCopy {
	
	table{
		width: 100%;
		background: #fbfbfb;
		margin-bottom: 15px;
		
		p{
			margin-bottom: 0;
		}
		
		td,th{
			padding:18px;
			border: 1px solid #e0e0e0;
		}
	}
	
	h4{
		font-weight: bold;
	}
}

// Typography

.centerJustify {
	text-align: center;
}