
//
// Helper classes
// --------------------------------------------------

.clearfix{
	@include clearfix;
}

// Quick floats
.pull-right {
  float: right;
}
.pull-left {
  float: left;
}

// Toggling content
.hide {
  display: none !important;
}
.show {
  display: block !important;
}

.text-right{
	text-align: right;
}