

$module: 'jump-links';

.#{$module} {
	$fontSize: 12;
	font-size: em($fontSize);
	font-family: $secondaryFontFamily;
	text-align: center;
	border-top: 1px solid $grey-10;
	text-transform: uppercase;
	position: relative;
	margin: em(20) 0 0;
	padding: 0 0 em(40);
	
	&:before, &:after {
		content: '';
		display: block;
		position: absolute;
		height: em(15);
		width: 1px;
		background-color: $grey-10;
		top: 0;
	}
	
	&:before {
		left: 0;
	}
	
	&:after {
		right: 0;
	}
	
	&__title {
		display: inline-block;
		position: relative;
		background-color: white;
		padding: 0 em(20);
		top: em(-15);
		color: $grey-11;
		font-weight: bold;
	}
	
	&__items {
		@include display(flex);
		@include justify-content(space-between);
		
		.content & {
			padding: 0;
		}
	}
	
	&__item {
		display: block;
		
		&:hover {
			text-decoration: none;
			color: tint($grey-11, 40%);
		}
	}
	
	&__icon-wrap {
		$fontSize: 12.8;
		width: em(73,$fontSize);
		height: em(73,$fontSize);
		border-radius: 50%;
		position: relative;
		margin: 0 auto em(14,$fontSize);
		
		> svg {
			position: absolute;
			top: 50%;
			left: 50%;
			@include transform(translateX(-50%)translateY(-50%));
			fill: white;
		}
	}
	
	&__icon-wrap--curriculum {
		@extend .#{$module}__icon-wrap;
		background-color: $color-1;
	}
	
	&__icon-wrap--exploring {
		@extend .#{$module}__icon-wrap;
		background-color: $color-2;
	}
	
	&__icon-wrap--family-support {
		@extend .#{$module}__icon-wrap;
		background-color: $color-3;
	}
	
	&__icon-wrap--higher-education {
		@extend .#{$module}__icon-wrap;
		background-color: $color-4;
	}
	
	&__icon-wrap--school-choice {
		@extend .#{$module}__icon-wrap;
		background-color: $color-5;
	}
	
	&__heading {
		$fontSize: 11;
		font-size: em($fontSize,12.8);
		max-width: em(90,$fontSize);
		transition: color 0.1s ease;
	}
}