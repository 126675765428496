

@charset "utf-8";

.floatfix {
	font-size: 0px;
	line-height: 0px;
	clear:both;
}
body {
	position: relative;
	font: 100%/1.4 'Open Sans', sans-serif;
	font-family: 'Open Sans', sans-serif;
	margin: 0;
	padding: 0;
	color: #242424;
}
.body_has_bg { background: url(/media/images/awards-gala-dinner-skin-3px_2407.gif) repeat-y center 0; }

img {
	max-width: 100%;
	height: auto;
}
	
.skin_spacer_top {
	width: 100%;
	height: 146px;
}
.bg_skin_lev1 {
	/*background: url(../img/ads/awards_gala_dinner_skin.jpg) no-repeat center 0;*/
	background: url(/media/images/awards-gala-dinner-post-event-skin_2406.jpg) no-repeat center 0;
	width: 100%;
	height: 147px;
	position: absolute;
	top: 0px;
}
.bg_skin_lev2 {
	background: url(/media/images/awards-gala-dinner-post-event-skin-sides_2405.jpg) no-repeat center 0;
	width: 100%;
	height: 753px;
	position: absolute;
}
.stick {
    position:fixed;
    top:0;
}
/* ~~ Element/tag selectors ~~ */
p,.story { 
	font-family: sans-serif;
	font-size: 14px;
	line-height: 1.8em;
	vertical-align: baseline;
	letter-spacing: normal;
	word-spacing: 0px;
	margin-bottom: 14px;
}
	
h1, h2, h3, h4, h5, h6 {
	font-family: 'Open Sans', sans-serif;
	margin-top: 0;	 /* removing the top margin gets around an issue where margins can escape from their containing div. The remaining bottom margin will hold it away from any elements that follow. */
	font-weight: 400;
	/*padding-right: 15px;
	padding-left: 15px; /* adding the padding to the sides of the elements within the divs, instead of the divs themselves, gets rid of any box model math. A nested div with side padding can also be used as an alternate method. */
}
h2 {
	font-size: 24px;
	font-weight: normal;
	line-height: 1.6em;
}
.col img  {
	height: auto;
}

a img { /* this selector removes the default blue border displayed in some browsers around an image when it is surrounded by a link */
	display:block;
}
.fill-div img { /* this selector removes the default blue border displayed in some browsers around an image when it is surrounded by a link */
	-webkit-box-sizing:border-box;
	-moz-box-sizing:border-box;
	-ms-box-sizing:border-box;
	box-sizing:border-box;
	border: 1px solid #f0f0f0;
	opacity: 1;
   transition: opacity .25s ease-in-out;
   -moz-transition: opacity .25s ease-in-out;
   -webkit-transition: opacity .25s ease-in-out;
}
#sidebanner_250x120 a img { border: 1px solid #f0f0f0; }

.fill-div img:hover, .fill-div img:active, .fill-div img:focus {
	/* IE 8 */
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
	/* IE 5-7 */
	filter: alpha(opacity=80);
	/* Netscape */
	-moz-opacity: 0.8;
	/* Safari 1.x */
	-khtml-opacity: 0.8;
	/* Good browsers */
	opacity: 0.8;
}


/* ~~ Styling for your site's links must remain in this order - including the group of selectors that create the hover effect. ~~ */
a {
	color: #4A7194;
	text-decoration: none; /* unless you style your links to look extremely unique, it's best to provide underlines for quick visual identification */
	
	transition: color .2s ease-in-out;
	-moz-transition: color .2s ease-in-out;
	-webkit-transition: color .2s ease-in-out;
}
a:visited {
	text-decoration: none;
}

a:hover {
	color: #73a6d4;
	text-decoration: underline;
}

a:active, a:focus { /* this group of selectors will give a keyboard navigator the same hover experience as the person using a mouse. */
	color: #4A7194;
}
p a {
	color: #4A7194;
	font-weight: bold;
}
li a {
	color: #4A7194;
	font-weight: bold;
}
p a:hover {
	color: #4A7194;
	text-decoration: underline;
}

.sitelimits {
	position:relative;
	width: 980px;
	margin: 0 auto; /* the auto value on the sides, coupled with the width, centers the layout */
	background: #fff;
}
/* ~~this fixed width container surrounds the other divs~~ */
.wrap {
	position:relative;
	width: 960px;
	margin: 0 auto; /* the auto value on the sides, coupled with the width, centers the layout */
	@include clearfix;
}
.wrap_content {
	width: 960px;
	margin: 0 auto; /* the auto value on the sides, coupled with the width, centers the layout */
}

/* ~~ the header is not given a width. It will extend the full width of your layout. It contains an image placeholder that should be replaced with your own linked logo ~~ */

#sticky-header {
	background-color: #fff;
	width: 960px;
}

.sticky-header__logo-wrap {
	display: inline-block;
	width: 60%;
	padding: 0 0 20px 0;
	vertical-align: top;
	
	@include bp(mobile) {
		display: block;
		width: 100%;
		padding: 20px 0;
		text-align: center;
	}
}

.header_fixed_pos {
	position: fixed;
}
.header_moving_pos {
	position: relative;
	z-index: 2;
}
#top_ad_area {
	position:relative;
	text-align:center;
	margin: 15px 0 15px 0;
	height: 152px;
	overflow: hidden;
}

.logo_main {
	position: relative;
	width: 235px;
  	height: 60px;
  	margin-right: 13px;
	display: inline-block;
	vertical-align: middle;
	float: none;
	
	background: url(/media/images/relocategloballogoblk_22504.svg);
	background-repeat: no-repeat;
}


.logo_main_strap_box {
	position: relative;
	height: 68px;
	float: left;
	margin: 3px 0 0 20px;
}
.logo_main_strap_mid {
	position: relative;
	padding: 8px 8px 0 0;
	font: 12px 'Open Sans', sans-serif;
	color: #242424;
	font-style: italic;
	height: 55px;
	float: none;
	display: inline-block;
	vertical-align: bottom;
}

.logo_main_strap_left {
	position: relative;
	width: 22px;
	height: 68px;
	background: url(/media/images/bracket-purple-left_2403.gif) 0 0;
	float: left;
}

.logo_main_strap_right {
	position: relative;
	width: 22px;
	height: 68px;
	background: url(/media/images/bracket-purple-right_2402.gif) 0 0;
	float: left;
}

/*------------------------ Account Login (top-right) ---------------------------------------------------------------------*/
.account_strip {
	width: 40%;
	height: auto;
	text-align: right;
	display: inline-block;
	
	@include bp(mobile) {
		display: block;
		width: 100%;
		text-align: center;
		margin: 0 0 10px 0;
	}
}

.account_strip__top {
	position: relative;
	margin: 0 0 5px 0;
}
.account_btn_main {
	border-left: 1px solid #ccc;
	font-size: 12px;
	padding: 13px 14px 0 14px;
	height: 27px;
	float: right;
	text-transform: uppercase;
}
.fake_signin_btn {
	position: absolute;
	top: -1px;
	right: 0;
	background-color: #cfcfcf;
	border-top: 1px solid #979696;
	border-left: 1px solid #979696;
	border-right: 1px solid #979696;
	padding: 13px 13px 1px 14px;
	z-index: 100000;
}
.account_btn_main:hover, account_btn_main:active, account_btn_main:focus { box-shadow:inset 1px 1px 2px rgba(0,0,0,0.08)!important; }

.social_media_cont { 
	padding-top: 0;
	@include clearfix;
	display: inline-block;
	vertical-align: middle;
}

.social_media_cont .roundel_social { background-position: 0px -24px!important; }

.social_media_cont .roundel_social {
	opacity: 1;
	transition: opacity .25s ease-in-out;
	-moz-transition: opacity .25s ease-in-out;
	-webkit-transition: opacity .25s ease-in-out;
}
.social_media_cont .roundel_social:hover, .social_media_cont .roundel_social:active, .social_media_cont .roundel_social:focus { 
	/* IE 8 */
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
	/* IE 5-7 */
	filter: alpha(opacity=80);
	/* Netscape */
	-moz-opacity: 0.8;
	/* Safari 1.x */
	-khtml-opacity: 0.8;
	/* Good browsers */
	opacity: 0.8;
}

/*------------------------ Dropdown Box for Account Login (top-right) ---------------------------------------------------------------------*/
.login-popup{
	display:none;
	background: #cfcfcf;
	padding: 18px 14px; 	
	font-size: 1.6em;
	position: absolute;
	left:807px;
	top: 40px;
	z-index: 99;
	border: 1px solid #979696;

	-webkit-box-shadow:0 5px 10px rgba(0,0,0,0.9);
	-moz-box-shadow:0 5px 10px rgba(0,0,0,0.9);
	box-shadow:0 5px 10px rgba(0,0,0,0.9);

}
img.btn_close {
	float: right; 
	margin: -28px -28px 0 0;
}
fieldset { 
	border:none; 
}
form.signin .textbox label { 
	display:block; 
}
form.signin .textbox span { 
	display:block;
}
form.signin p, form.signin span { 
	color:#999; 
	font-size:11px; 
	line-height:18px;
} 
form.signin .textbox input { 
	background:#fff; 
	border: 1px solid #979696;
	color:#000; 
	font: 13px Arial, Helvetica, sans-serif;
	padding: 9px;
	width: 227px;
	margin-bottom: 14px;
}
form.signin input:-moz-placeholder { color:#bbb; text-shadow:0 0 2px #000; }
form.signin input::-webkit-input-placeholder { color:#bbb; text-shadow:0 0 2px #000;  }

.button { 
	background-color: #7e5c91;
	border: 1px solid #70418a;
	font-size: 13px;
	color: #fff;
	text-transform: uppercase;
	font-family: 'Open Sans', sans-serif!important;
	font-weight: 400;
	cursor:pointer;
	padding: 7px;
	margin-top:4px;
	width:247px;
	
	border-radius: 2px;
	-webkit-border-radius: 2px;
	-moz-border-radius: 2px;
	-ms-border-radius: 2px;
	
	transition: all .25s ease-in-out;
	-moz-transition: all .25s ease-in-out;
	-webkit-transition: all .25s ease-in-out;
}
.button:hover { 
	background-color: #9374a4;
	border-color: #7b6189;
}
.form_label {
	color:#000; 
	font: 13px Arial, Helvetica, sans-serif;
	padding: 3%;
	z-index:200px;
	top: -20px;
}
form.register .textbox input { 
	background:#fff; 
	border: 1px solid #979696;
	color:#000; 
	font: 13px Arial, Helvetica, sans-serif;
	padding: 3%;
	width: 90%;
	margin: 12px 0 12px 0;
}
form.register .textbox input:focus {
    border-color: #08c;
    -webkit-box-shadow: 0 0 6px rgba(0, 136, 204, 0.4);
       -moz-box-shadow: 0 0 6px rgba(0, 136, 204, 0.4);
            box-shadow: 0 0 6px rgba(0, 136, 204, 0.4);
}
form.number_input .textbox input { 
	background:#fff; 
	border: 1px solid #979696;
	color:#000; 
	font: 13px Arial, Helvetica, sans-serif;
	padding: 3%;
	width: 20%;
	margin: 12px 0 22px 0;
}
.form_title_h2 {
	font-size: 17px;
	padding: 0 0 15px 10px;
	font-family: 'Open Sans', sans-serif;
	font-weight: 600;
}
.form_title_h3 {
	font-size: 14px;
	font-weight: normal;
	font-family: 'Open Sans', sans-serif;
	font-weight: 600;
}
.form_p {
	font-family: sans-serif;
	font-size: 12px;
	line-height: none;
	vertical-align: middle;
	letter-spacing: normal;
	word-spacing: 0px;
	display: inline;
}
.form_caption_float_right {
	float: right;
	text-align: right;
	margin-right: 12px;
	width: 85%;
}
.checkboxes label {
	display: block;
	padding-right: 10px;
	white-space: nowrap;
}
.checkboxes input {
	vertical-align: middle;
	padding-bottom: 20px;
}
.checkboxes label span {
	vertical-align: middle;
}
.form_number_entry_box {
	width: 60px;
}



/*------------------------ Breadcrumb ---------------------------------------------------------------------*/
#breadcrumb_main_container {
	/*padding: 10px 0 13px 0;*/
	/*border-bottom: solid 1px #ccc;*/
	background: url(/media/images/line-breadcrumb_2401.gif) 0 45px repeat-x #fff;
	width:960px;
	height: 46px;
	line-height: 43px;
}
.breadcrumb_box {
	font-size: 12px;
	color: #525252;
	font-family: sans-serif;
	padding: 3px 6px 4px 0;
	position: relative;
	margin-right: 13px;
}
.breadcrumb_box:after {
	content: ' ';
	height: 0;
	position: absolute;
	width: 0;
	
	border: 4px solid transparent;
	border-left-color: #b199be;

	left: 100%;
	top: 50%;
	margin-top: -4px;
	
}
.breadcrumb_box_last {
	font-size: 12px;
	color: #525252;
	font-family: sans-serif;
	font-weight: bold;
	padding: 3px 6px 4px 0;
	position: relative;
	margin-right: 13px;
}


/*------------------------ SIDEBAR ---------------------------------------------------------------------*/
.sidebar1 {
	font-family: 'Open Sans', sans-serif;
	font-weight: 300;
	position:relative;
	float: right !important;
	width: 250px;
	margin: 19px 0 0 0;
	z-index: 1;
}
.sidenav {
	font-family: 'Open Sans', sans-serif;
	font-weight: 300;
	position:relative;
	float: left !important;
	width: 250px;
	padding: 0px 19px 0 0;
	margin: 14px 0 0 0;
}
.home_content2 {
	position: relative;
	width: 670px;
	margin: 19px 0 0 0;
	padding: 0px 19px 0 0;
	border-right: 1px dotted #ccc;
	float:left;
}
.content {
	position: relative;
	width: 670px;
	margin: 19px 0 0 0;
	padding: 0px 19px 0 0;
	border-right: 1px dotted #e6e6e6;
	float:left;
}
.content_grid {
	position: relative;
	margin: 19px 0 0 0;
}
.content_full_width {
	width: 960px;
	margin: 19px 0 0 0;
	/*background: #0F3;*/
	min-height: 800px;
}
.content_awards_header {
	position: relative;
	width: 670px;
	margin: 0 0 0 0;
	padding: 0;
	float:left;
	border-left: 1px dotted #e6e6e6;
}

.content_awards {
	position: relative;
	width: 670px;
	margin: 14px 0 19px 0;
	padding: 100px 0 0 19px;
	float:left;
	border-left: 1px dotted #e6e6e6;
	min-height:800px;
}

/* ~~ This grouped selector gives the lists in the .content area space ~~ */
.content ul, .content ol { 
	padding: 0 15px 15px 40px; /* this padding mirrors the right padding in the headings and paragraph rule above. Padding was placed on the bottom for space between other elements on the lists and on the left to create the indention. These may be adjusted as you wish. */
}

/* ~~ The footer ~~ */
.footer {      
	position:relative;
	margin-top: 60px!important;
	padding-bottom: 40px;
	background-color: #252525;
	color: #fff;
	clear: both; /* this clear property forces the .container to understand where the columns end and contain them */
}
.footer_top {      
	position:relative;
	margin-top: 60px;
	padding: 25px 0 10px 0;
	background-color: #252525;
	color: #fff;
	clear: both; /* this clear property forces the .container to understand where the columns end and contain them */
	border-bottom: 1px solid #363636;
}
.footer_bot {      
	position:relative;
	margin-top: 60px;
	padding: 25px 0 10px 0;
	background-color: #252525;
	color: #fff;
	clear: both; /* this clear property forces the .container to understand where the columns end and contain them */
	border-top: 1px solid #363636;
}
#logo_main_footer {
	background: url(/media/images/relocategloballogowht_22503.svg) 0 0 no-repeat;
	width: 165px;
  	height: 42px;
	margin-bottom: 12px;
}
.ftr_inner_colspan {
	display: block;
	float:left;
	width: 50%;
}
.ftr_inner_colspan_bdr {
	display: block;
	float:left;
	width: 50%;
	/*-webkit-box-sizing:border-box;
	-moz-box-sizing:border-box;
	-ms-box-sizing:border-box;
	box-sizing:border-box;
	border-right: 1px solid #363636;*/
}
.ftr_pad_lft {
	padding-left: 8px;
}
p.ftr {
	margin-bottom: 8px;
	line-height: 1.5em;
	
	font-family: 'Open Sans', sans-serif;
	font-weight: 300;
	font-size: 12px;
	color: #fff;
}
h3.ftr {
	margin: 20px 0 20px 0;
	line-height: 1.5em;
	font-family: 'Open Sans', sans-serif;
	font-weight: 400;
	font-size: 20px;
	color: #fff;
}
h4.ftr {
	margin: 18px 0 8px 0;
	line-height: 1.5em;
	font-family: 'Open Sans', sans-serif;
	font-weight: 600;
	font-size: 12px;
	color: #8bd0d4;
}
p.ftr:hover, p.ftr:active, p.ftr:focus, h4.ftr:hover, h4.ftr:active, h4.ftr:focus { 
	color: #bf77d9;
	text-decoration: underline;
}

#address_footer {
	display: inline-block;
	border-top: 1px solid #6a6a6a;
	padding-top: 6px;
}

/* ~~ miscellaneous float/clear classes ~~ */
.fltrt {  /* this class can be used to float an element right in your page. The floated element must precede the element it should be next to on the page. */
	float: right;
	margin-left: 8px;
}
.fltlft { /* this class can be used to float an element left in your page. The floated element must precede the element it should be next to on the page. */
	float: left;
	margin-right: 8px;
}
.clearfloat { /* this class can be placed on a <br /> or empty div as the final element following the last floated div (within the #container) if the #footer is removed or taken out of the #container */
	clear:both;
	height:0;
	font-size: 1px;
	line-height: 0px;
}



#navigation { position: fixed; }

.nav a { text-decoration: none; }

.nav .nav_item { list-style: none; }

/* Menu Container */
.nav {
	display: flex;
	position: relative;
	height: 61px;
	background: #252525;
}

.nav_item{
	flex: 1 1 auto;
}

/* Menu Links */
.main_nav_a {
	position: relative;
	display: flex;
	height: 61px;
	flex-direction: column;
	justify-content: center;
	padding: 0 32px 0 20px;
	
	font-family: 'Open Sans', sans-serif;
	font-weight: 300;
	font-size: 12px;
	text-transform:uppercase;
	color: #fff;
	
	transition: all .2s ease;

	.nav_item:last-child &{
		background: none;
	}
	text-align: center;
	background: url(/media/images/nav-arrow-bevel_2399.png) right 0 no-repeat;
}


.no_mega {
	background: url('/media/images/nav-bevel_3052.png') right 0 no-repeat;
	padding-right: 20px;
}
.nav_item:hover .main_nav_a, .nav_item:active .main_nav_a, .nav_item:focus .main_nav_a { 
	color: #fff!important;
}
.nav_item:hover .has_hover, .nav_item:active .has_hover, .nav_item:focus .has_hover { 
	background-color: #4b4441!important; 
	background-position: right -55px!important; 
	cursor: pointer;
	color: #fff!important;
}
.nav_item_active {
	color: #fff!important;
}
.main_nav_a.disabled, .main_nav_a.disabled:hover{ background-color: #886d97; }

.nav_root_selected { 
	background-color: #886d97!important; 
	background-position: right -55px!important; 
}
.kill_link {
	cursor: default!important;
}
.nav > .nav_item:first-child > a {
	border-left: none;
	width: auto;
	background: url(/media/images/nav-arrow-bevel_2399.png) right 0 no-repeat;
}
/* Menu Dropdown */
.nav > .nav_item > .mega_box_old {
	position: absolute;
	display: block;
	width: 960px;
	top: 61px;
	left: 0;
	padding: 25px 0 25px 0;
	opacity: 0;
	visibility: hidden;
	overflow: hidden;
	background-color: #ddd;
	padding-bottom:0px !important;
	margin-bottom:0px !important;
	z-index:100;
}
.nav > .nav_item > .mega_box {
	position: absolute;
	display: block;
	width: 960px;
	top: 61px;
	left: 0;
	opacity: 0;
	visibility: hidden;
	overflow: hidden;
	background-color: #fafafa;
	padding-bottom:0px !important;
	margin-bottom:0px !important;
	z-index:100;
	border-top: 5px solid #4b4441;
	
	-webkit-box-shadow:0 5px 10px rgba(0,0,0,0.5);
	-moz-box-shadow:0 5px 10px rgba(0,0,0,0.5);
	box-shadow:0 5px 10px rgba(0,0,0,0.5);
	/*
	-webkit-transition: all .3s ease .15s;
	-moz-transition: all .3s ease .15s;
	-o-transition: all .3s ease .15s;
	-ms-transition: all .3s ease .15s;
	transition: all .3s ease .15s;
	*/
}
#mega_box2 {
	position: absolute;
	display: block;
	width: 960px;
	top: 61px;
	left: 0;
	padding: 0 0 25px 0;
	/*opacity: 0;
	visibility: hidden;*/
	overflow: hidden;
	padding-bottom:0px !important;
	margin-bottom:0px !important;
	z-index:100;
	/*height:226px;*/
	/*
	-webkit-transition: all .3s ease .15s;
	-moz-transition: all .3s ease .15s;
	-o-transition: all .3s ease .15s;
	-ms-transition: all .3s ease .15s;
	transition: all .3s ease .15s;
	*/
}
.mega_box li a {
	color: #4A7194!important;
}
.mega_box li a:hover, .mega_box li a:active, .mega_box li a:focus {
	text-decoration: underline;
}
.mega_box_half {
	width: 50%;
	height: 100%;
	float: left;
	&:first-child {
		border-right: 1px solid #ccc;
		box-sizing: border-box;
	}
	
	.mega_col_narrow{
		width: 50%;
	}
}
.mega_box_third {
	width: 320px;
	float: left;
	
	.mega_col_narrow{
		width: 50%;
	}
}

.mega_box_half h3 {
	padding: 15px 22px;
}
#mega_box_split1 { width: 23%;}
#mega_box_split2 { width: 75%; padding-left: 1%; border-left: 1px solid #ccc; }

.fullWidth{
    padding-top: 30px;
}

.magazine {
    cursor: pointer;
    margin-top: 30px;
    width: 25%;
    @include display(flex);
    @include flex-direction(column);
    @include align-items(center);

}
.nav > .nav_item:hover > div {
	opacity: 1;
	visibility: visible;
	overflow: visible;
}
/* Menu Content Styles */
.nav .nav-column {
	position: relative;
	float: left;
	width: 104px;
	padding: 0 0 0 13px;
	border-right: 1px dotted #aeaeae;
	z-index:60000;
}
.mega_column {
	position: relative;
	float: left;
	z-index:60000;
	padding: 1px 20px 0 20px!important;
	width: 200px;
	margin: 22px 0 25px -1px!important;
	border-right: 1px dotted #ccc !important;
	
	&:last-child{
		border-right: none !important;
	}
}
.mega_column_relo {
	position: relative;
	float: left;
	z-index:60000;
	padding: 1px 20px 0 20px!important;
	width: 160px;
	margin: 22px 0 25px -1px!important;
	border-left: 1px dotted #ccc!important;
}
.mega_col_narrow {
	width: 25%;
	box-sizing: border-box;
}
.nav .mega2_nav_column {
	position: relative;
	float: left;
	width: 154px;
	padding: 0 0 20px 13px;
	/*border-right: 1px dotted #aeaeae;*/
	z-index:60000;
}
.nav .nav-column-news {
	position: relative;
	float: left;
	width: 192px;
	padding: 20px 0 20px 13px;
	border-right: 1px dotted #aeaeae;
	z-index:60000;
}

/* --------------- FEATURE BOX (first created for megabox area) ------------------------------------------------------------------- */
.feature_box {
	float: right;
	height: 251px;
	width: 541px;
	margin-top: 16px !important;
    margin-right: 16px !important;
    margin-bottom: 16px !important;
    background-size: cover;
    
    strong{
    	color: #f9df00;
    	font-weight: normal;
	}

	p{
	    position: relative;
	    line-height: 1.4em;
	    font-family: 'Open Sans', sans-serif;
	    font-weight: 400;
	    font-size: 11px;
	    color: #fff;
	    width: 275px;
	    padding: 0 0 10px 45px;
	}
}
.feature_box_h1 {
	position: relative;
	line-height: 1.1em;
	font-family: 'Open Sans', sans-serif;
	font-size: 21px;
	font-weight: 400;
	text-transform: uppercase;
	color: #fff;
	width: 308px;
	padding: 40px 0 20px 45px;
	text-align: left!important;
}
.feature_box_h1_alt {
	font-family: 'Open Sans', sans-serif;
	font-weight: 300;
	/*color: #e07e2f;*/ /*(Autumn2013 Issue Orange)*/
	/*color: #cc83f8;*/ /*(Winter2013 Issue Purple)*/
	color: #f9df00; /*(Spring2014 Issue Yellow)*/ 
	font-weight: normal;
}
.feature_box_para {
	position: relative;
	line-height: 1.4em;
	font-family: 'Open Sans', sans-serif;
	font-weight: 400;
	font-size: 11px;
	color: #fff;
	width: 275px;
	padding: 0 0 10px 45px;
	text-align: left!important;
}

.story_first_para--no-image > p {
	margin-bottom: em(-10);
}

.feature_box_para_alt { 
	/*color: #e07e2f;*/ /*(Autumn2013 Issue Orange)*/
	/*color: #cc83f8; /*(Winter2013 Issue Purple)*/ 
	color: #f9df00; /*(Spring2014 Issue Yellow)*/ 
}

a .feature_box { /* this selector removes the default blue border displayed in some browsers around an image when it is surrounded by a link */
	border: none;
	opacity: 1;
   transition: opacity .25s ease-in-out;
   -moz-transition: opacity .25s ease-in-out;
   -webkit-transition: opacity .25s ease-in-out;
}
a .feature_box:hover, a .feature_box:active, a .feature_box:focus {
	/* IE 8 */
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=95)";
	/* IE 5-7 */
	filter: alpha(opacity=95);
	/* Netscape */
	-moz-opacity: 0.95;
	/* Safari 1.x */
	-khtml-opacity: 0.95;
	/* Good browsers */
	opacity: 0.95;
}
.nav .nav-column-current_mag_text{
	float:left;
	left:10px;
}
.nav .nav-column-current_mag{
	float: left;
}
.nav-column-first { padding-left: 22px!important; }
.nav-column-last, .nav-column-double { border-right: none!important; }

.nav .nav-column h3 {
	line-height: 14px;
	font-family: 'Open Sans', sans-serif;
	font-weight: 400;
	font-size: 11px;
	color: #886d97;
	text-transform: uppercase;
	height: 17px;
	margin: 0;
}
.nav .nav-column-news h3 {
	line-height: 12px;
	font-family: Helvetica, sans-serif;
	font-size: 13px;
	color: #886d97;
	text-transform: uppercase;
	height: 17px;
	margin: 0;
}
.nav .nav-column h3.orange { color: #fff; }
/*
.nav .nav-column li a {
	display: block;
	line-height: 22px;
	font-family: 'Open Sans', sans-serif;
	font-weight: 400;
	font-size: 11px;
	color: #555;
}
.nav .nav-column-news li a {
	display: block;
	line-height: 22px;
	font-family: 'Open Sans', sans-serif;
	font-weight: 400;
	font-size: 13px;
	color: #555;
	padding-bottom: 5px;
}
*/
.nav .nav-column li a:hover { color: #4A7194; }
.nav .nav-column-news li a:hover { color: #4A7194; }

.nav_section_main {
	display: block;
	line-height: 19px;
	font-family: 'Open Sans', sans-serif;
	font-weight: 400;
	font-size: 13px;
	color: #3f3f3f;
	margin-bottom: 10px!important;
}
.nav_section_sub {
	display: block;
	line-height: 17px;
	font-family: 'Open Sans', sans-serif;
	font-weight: 400;
	font-size: 11px;
	color: #555;
	text-indent: 10px;
}
.mega_col_h3 {
	display: block;
	line-height: 28px;
	font-family: 'Open Sans', sans-serif;
	font-size: 20px;
	color: #3f3f3f;
	font-weight: 600;
 	margin: 15px 0 -15px -4px;
}
.mega_col_a_main {
	display: block;
	line-height: 19px;
	font-family: 'Open Sans', sans-serif;
	font-weight: 700;
	font-size: 15px;
	color: #3f3f3f;
	text-transform: uppercase;
	padding: 10px 0 5px 0!important;
	margin-top: 7px!important;
	border-top: 1px solid #ccc!important;
	width: 195px;
}
.mega_col_a_genrl {
	display: block;
	line-height: 22px;
	font-family: 'Open Sans', sans-serif;
	font-weight: 400;
	font-size: 14px;
	color: #3f3f3f;
	margin: 0 0 15px 0!important;
}
.mega_col_a_sub {
	display: block;
	line-height: 19px;
	font-family: 'Open Sans', sans-serif;
	font-weight: 400;
	font-size: 13px;
	color: #3f3f3f;
	margin: 2px 0!important;
}
.mega_col_a_sub_alt {
	display: block;
	line-height: 19px;
	font-family: 'Open Sans', sans-serif;
	font-weight: 400;
	font-size: 13px;
	color: #3f3f3f;
	margin: 0 0 15px 0!important;
}

/* Social links styling */

$module: 	'socialLinks';

.#{$module} {
	
	&__defaults {
		display: inline-block;
	}
	
	&__fb {
		@extend .#{$module}__defaults;
		margin-top: -2px;
		vertical-align: top;
	}
	&__twitter {
		@extend .#{$module}__defaults;
	}
}

/// EMAIL SVG: here because no SVG folder and related to social media icons!!!!!
//////////////////////////

.svg-email-icon {
	height: em(24,16);
	
	path {
		fill: $purple;
		fill-rule: evenodd;
		&:hover {
			fill: #a6a6a6;
		}
	}
	
}


/* --------------- STORY STYLING ------------------------------------------------------------------- */

.socialLinks--story {
	text-align: right;
	display: block;
	width: auto;
	padding: 0;
	right: 0;
	float: right;
	margin-top: 10px;
	
	@include bp(mobile) {
	    width: 100%;
	    margin: 0 auto;
	    text-align: center;
	    margin-top: 15px;	
	}
	
	> li {
		max-width: 20%;
		max-height: em(24);
	    display: inline-block;
    	margin-left: 10px;
    	@include bp(mobile) {
		    display: inline-block;
    		margin-left: 10px;	
		}
	}
	
	a {
		background-position: 0 -24px;
	    display: block;
	    height: 24px;
	    width: 24px;
		
		&:hover {
			background-position: 0 0;
		}
	}
}

#headline_box {
	margin-bottom: 25px;
	border-bottom: solid 1px #f0f0f0;
	width: 484px;
}
#headline_img {
	float:left;
	width: 484px;
	height: 238px;
	margin: 0 20px 5px 0;
	overflow: hidden;
	background: #f5f5f5;
}
#headline_text_cont {
	float: left;
	width: 484px;
	padding: 8px 0 10px 0;
}
.headline_date {
	font-family: sans-serif;
	font-size: 9px;
	color: #242424;
	text-transform: uppercase;
	float: left;
	margin-top: 14px;
}
.headline_main {
	font-family: 'Open Sans', sans-serif;
	font-weight: 700;
	font-size:24px;
	text-transform: uppercase;
	text-align:left;
	padding-bottom:15px;
	line-height: 1.1em;
	vertical-align: baseline;
	letter-spacing: -0.6px;
	word-spacing: 0px;
}
.catch_text {
	font-family: sans-serif;
	font-size: 12px;
	line-height: 1.5em (18px);
	vertical-align: baseline;
	letter-spacing: normal;
	word-spacing: 0px;
	padding-bottom: 19px;
}
.read_more {
	float: right;
	margin-top: 12px;
}
.read_more a {
	font-size: 10px;
	color: #4A7194;
	font-family: 'Open Sans', sans-serif;
	font-weight: 700;
	text-transform: uppercase;
}



.sub_headline_box {
	font-size:12px;
	width:220px;
	margin-bottom: 20px;
	border-bottom: solid 1px #f0f0f0;
	padding-bottom: 10px;
}
.sub_headline_img{
	width:220px;
	height:120px;
	margin: 0 10px 8px 0;
	overflow: hidden;
	background: #f5f5f5;
}
.sub_headline_intro_cont {
	width: 220px;
	padding-top:8px;
}
.sub_headline_main {
	font-family: 'Open Sans', sans-serif;
	font-weight: 600!important;
	font-size:13px;
	line-height: 1.5em (19.5px);
	text-transform: uppercase;
	text-align:left;
	padding-bottom:13px;
	vertical-align: baseline;
	/*letter-spacing: -0.6px;*/
	word-spacing: 0px;
}
.col1_subcol1 {
	width: 220px;
	float: left;
	padding-right: 20px;
	border-right: 1px dotted #e6e6e6;
}
.col1_subcol2 {
	width: 220px;
	float: left;
	margin-left: 20px;
}
.col2_headline_box {
	font-size:12px;
	margin: 10px 0 0px 0px;
	border-bottom: solid 1px #f0f0f0;
	margin-bottom: 18px;
}
.col2_new_mag_ad {
	width:150px;
	height:291px;
	background:url(/media/images/magazine-out-now-03_2397.jpg) 0 0 #f5f5f5;
	margin-bottom: 25px;
}
.col2_headline_img{
	width:150px;
	height:95px;
	margin: 0 10px 10px 0;
	overflow: hidden;
	background: #f5f5f5;
}
.col2_headline_main {
	font-family: 'Open Sans', sans-serif;
	font-weight: 600;
	font-size:13px;
	line-height: 1.5em (19.5px);
	/*text-transform: uppercase;*/
	text-align:left;
	vertical-align: baseline;
	letter-spacing: -0.6px;
	word-spacing: 0px;
}
#micro_headline_box {
	font-size:12px;
	float:left;
	background-color:#fff;
	margin-top:15px;
}
.micro_headline_main{
	font-size:12px;
	text-align:left;
	padding-bottom:10px;
}
#text_headlines{
	font-size:12px;
	float:right;
	background-color:#fff;
	width:310px;
	margin-top:15px;
}
#std_box{
	background-color:#fff;
	padding-left:10px;
	padding-top: 10px;
	padding-bottom:10px;
	padding-right:10px;
	border:1px solid #ccc;
}

#pwc_box {
	background:url(/media/images/technology-sidebar_2396.png) no-repeat 0 0 #fff;
	width: 240px;
	height: 400px;
}
#newsletter_signup_button	 {
	text-align:center;
}
#newsletter_signup_text {
	font-size:10px;
}
#sidebanner_250x120{
	position:relative;
	width:250px;
	height:120px;
	padding-bottom: 30px;
	border-bottom: solid 1px #f0f0f0;
}
.sidebanner_top_margin { margin-top: 30px; }

.section_header_cont {
	background:url(/media/images/pattern-micro-grid_2395.gif) 0 0 #e1e1e1;
	margin-bottom: 12px;
}
.section_header_cont_new {
	background:url(/media/images/pattern-micro-grid_2395.gif) 0 0 #e1e1e1;
	margin-bottom: 6px;
}
.section_header {
	font-size: 11px;
	color: #fff;
	text-transform: uppercase;
	font-family: 'Open Sans', sans-serif!important;
	font-weight: 600;
	padding: 5px 12px 5px 12px;
	background-color: #886d97;
	display: inline-block;
	vertical-align: top;
}
.section_header_imm { background-color: #3c9dab; }

.section_header_full_width {
	display: block;
}
.section_header_large {
	background:url(/media/images/pattern-micro-grid_2395.gif) 0 0 #999;
	margin-bottom: 12px;
}
.section_header_large_intro {
	background:url(/media/images/pattern-micro-grid_2395.gif) 0 0 #999;
	margin-bottom: 32px;
}
.section_header_large_inner {
	/*display: inline-block;*/
	font-size: 16px;
	color: #fff;
	text-transform: uppercase;
	font-family: 'Open Sans', sans-serif!important;
	font-weight: 400;
	padding: 7px 14px 9px 14px;
	background-color: #523d5a;
	display: inline-block;
}
.section_header_topmargin { margin-top: 15px; }

// facebook slick gallery wrapper
.fb-slick__wrapper {
	margin: 10px 0 0 0;
}

.fb-slick__wrapper--large {
	@extend .fb-slick__wrapper;
	
	.slick__thumbnails .slick-track {
		height: em(482);
	}
}
	

.sponsor_lb {
	margin: 0 0 20px 0;
}

.sidebar_listing {
	font-size: 13px;
	text-indent: 1px;

	line-height: 1.5em (19.5px);
	border-bottom: solid 1px #f0f0f0;
	padding: 0 0 15px 0;
	
	&:nth-child(even){
		background-color: #F5F5F5;
	}
	
	.sidebar_box &{
		background-color: transparent;
	}
	
}



.thumb_listing {
	font-size: 13px;
	text-indent: 1px;

	line-height: 1.5em (19.5px);
	padding: 0 0 20px 0;
	border-bottom: solid 1px #f0f0f0;
}
.thumb_listing_no_rule {
	font-size: 13px;
	text-indent: 1px;

	line-height: 1.5em (19.5px);
	padding: 0 0 20px 0;
	clear: both;
}
.sidebar_listing_img {
	background: #f5f5f5;
	overflow:hidden;
	width: 100px;
	height: 64px;
	float: left;
	margin: 0 10px 0 0;
}
.sidebar_listing_title {
	width: 150px;
	height: 60px;
	padding-left: 10px;
	float: left;
}
.sidebar_listing {
	font-size: 13px;
	text-indent: 1px;

	line-height: 1.5em (19.5px);
	border-bottom: solid 1px #f0f0f0;
	padding: 15px 0 15px 0;
	
	&:last-child{
		border-bottom: none;
	}
}

/*.sidebar_listing:first-of-type { padding-top: 0px!important; }*/

.sidebar_hdr { margin-bottom: -1px!important; }

.extra_articles_list {
	font-size: 13px;
	text-indent: 1px;

	line-height: 1.5em (19.5px);
	border-bottom: solid 1px #f0f0f0;
	padding: 2.6%;
}
.extra_articles_list_box{
	margin-bottom: 40px;
}
.extra_articles_list_box:first-child { padding-top: 0; }

.general_listing {
  	font-size: 13px;
	text-indent: 1px;
	line-height: 1.5em (19.5px);
	border-bottom: solid 1px #ccc;
	padding: 13px 0 14px 0;
}

/*------------------------ Story ---------------------------------------------------------------------*/
.story{
	@include clearfix;
	
	h2 {
		font-family: 'Open Sans', sans-serif;
		font-size: 19px;
		font-weight: 600;
		padding: 0px 0;
		line-height: 1.6em;
		margin: 0 0 10px 0;
	}
	
	h3{
		font-size: 16px;
		font-weight: 600;
		margin-bottom: 12px;
	}
	
	h4 {
		font-size: 14px;
		font-weight: 600;
	}
}


.story_attrib {
	position: absolute;
	bottom: 0;
	margin-bottom: 0;
	padding: 5px;
	font-size: 10px;
	background-color: #FFF;
	
	/* IE 8 */
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=65)";
	/* IE 5-7 */
	filter: alpha(opacity=65);
	/* Netscape */
	-moz-opacity: 0.65;
	/* Safari 1.x */
	-khtml-opacity: 0.65;
	/* Good browsers */
	opacity: 0.65;
}
.story__attrib--large {
	@extend .story_attrib;
	font-size: 19px;
	font-weight: 600;
}
.story_info_strip{
	margin-top: 19px;
	height: 51px;
	border-bottom: solid 1px #b8b8b8;
}
.story_author_pic{
	margin-right: 12px;
	float: left;
}
.social_media_holder { margin-top: 12px; }

.story_intro_box {
	padding-bottom: 0px;
}
.story_intro_box_awards {
	margin: 5px 0 15px 0;
	padding-bottom: 12px;
	border-bottom: solid 1px #f7f7f7;
	height: 100%;
}
.story_headline {
	position: relative;
	font-family: 'Open Sans', sans-serif;
	font-size: 30px;
	text-align:left;
	line-height: 1.3em;
	vertical-align: baseline;
	letter-spacing: -0.6px;
	word-spacing: 0px;
	margin-bottom: 14px;
	font-weight: 600!important;
}
.story_first_para p, .story_author_name {
	position: relative;
	font-family: 'Open Sans', sans-serif;
	font-weight: 400;
	font-size: 14px;
	text-align:left;
	vertical-align: baseline;
	letter-spacing: -0.6px;
	word-spacing: 0px;
}
.story_first_para p {
	margin-bottom: 24px;
  line-height: 1.7em;
}
.story_author_name {
	margin: 15px 0;
	padding-right: 10px;
	border-right: 1px solid #b8b8b8;
	line-height: 1.3em;
}
.story_ad_ftre_strip {
	position: relative;
	margin-top: -60px;
	width: 100%;
	padding: 10px;
	font-size: 10px;
	background-color: #fff;
	
	/* IE 8 */
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=85)";
	/* IE 5-7 */
	filter: alpha(opacity=85);
	/* Netscape */
	-moz-opacity: 0.85;
	/* Safari 1.x */
	-khtml-opacity: 0.85;
	/* Good browsers */
	opacity: 0.85;
}
.newsletter__link {
	font-weight: 400;
	font-size: 14px;
	line-height: 1.6;
}
.newsletter__headline {
	margin: 5px 0;
	font-size: 18px;
	color: rgb(80,181,33);
}
	

.story_ad_ftre {
	position: relative;
	font-family: 'Open Sans', sans-serif;
	font-weight: 400;
	font-size: 14px;
	text-align:left;
	vertical-align: baseline;
	letter-spacing: -0.6px;
	word-spacing: 0px;
	line-height: 1.3em;
	margin-top: 10px;
}
.story_headline_big {
	position: relative;
	font-family: 'Open Sans', sans-serif;
	font-weight: 600;
	font-size:34px;
	text-align:left;
	line-height: 1.3em;
	vertical-align: baseline;
	letter-spacing: -0.6px;
	word-spacing: 0px;
}
.story_headline_big_half_col {
	position: relative;
	width: 335px;
	font-family: 'Open Sans', sans-serif;
	font-weight: 600;
	font-size:34px;
	text-align:left;
	line-height: 1.3em;
	vertical-align: baseline;
	letter-spacing: -0.6px;
	word-spacing: 0px;
	float: left;
}
.story_date {
	font-family: sans-serif;
	font-size: 12px;
	color: #242424;
	text-transform: uppercase;
	margin: 15px 0 15px 10px;
	padding-top: 2px;
	vertical-align: baseline;
}
#story_image {
	width:670px;
	height: auto;
	position: relative;
}

#story_media {
	background:no-repeat 0 0 #fff;
	width:670px;
	height: 480px;
	overflow: hidden;
}
.story ul {
	padding-left: 0;
	margin-left: 0;
	font-family: sans-serif;
	font-size: 12px;
	line-height: 1.8em;
	vertical-align: baseline;
	letter-spacing: normal;
	word-spacing: 0px;
}

.body_ul {
	padding-left: 0;
	margin-left: 0;
	font-family: sans-serif;
	font-size: 12px;
	line-height: 1.8em;
	vertical-align: baseline;
	letter-spacing: normal;
	word-spacing: 0px;
}
ol {
	padding-left: 0;
	margin-left: 0;
	font-family: sans-serif;
	font-size: 12px;
	line-height: 1.8em;
	vertical-align: baseline;
	letter-spacing: normal;
	word-spacing: 0px;
}
.story ul>li { 
	position: relative;
	padding: 0 0 0 18px;
	
	&:before {
	    content: '';
	    border-top: 8px solid transparent;
	    border-bottom: 8px solid transparent;
	    border-left: 8px solid #b198bf;
	    display: inline-block;
	    position: relative;
	    left: 0;
	    top: 5px;
	    margin: 0 10px 0 0;
	}
}
.body_li { 
	background:url(/media/images/bullet-arrow_20119.png) no-repeat 0 6px;
	padding-left: 18px;
}
/*-----Lee's edit START*/
.story_holder{
    overflow: hidden; 
}
#story_holder_left{
	padding-right:-40px;
	width:50%;
	float:left; /* add this */
}
#story_holder_right{
	overflow: hidden;
	width:46%;
	padding-left:15px;
}
/*-----Lee's edit END*/

#comments {
	font-size:28px;
	text-align:center;
}

#story_comments{
margin-top:10px;
background-color:#fff;
padding-left:10px;
padding-top:10px;
padding-bottom:10px;
padding-right:10px;
border:1px solid #cccccc;
}

#comments_box_name{
Font-size:20px;
float:left;
}


#comments_box_time{
Font-size:12px;
float:right;
}

#comments_box_content{
Font-size:12px;
float:right;
padding-top:20px;
padding-bottom:20px;
}


#comments_box_report{
Font-size:12px;
float:left;
padding-top:20px;
}

#comments_box_vote{
text-align:right;
}

#add_story{
}

#add_story label{
font-size:20px;
}

#add_story_textarea{
margin-bottom:20px;
}

#column1{
	float:left;
	width:484px;
	padding-bottom:50px;
	padding-right: 18px;
	border-right: 1px dotted #e6e6e6;
}

#column2{
	float:right;
	width:150px;
	padding-bottom:50px;
}

#wrapper {
	width:600px; 
	margin:0 auto; 
	border-radius:0 0 5px 5px;
	-moz-border-radius:0 0 5px 5px;
	-webkit-border-radius: 0 0 5px 5px;
	background:#fff; 
	border:1px solid #ccc; 
	padding:25px; 
	border-top:none;
}



.calendar {
	font-family: 'Trebuchet MS', Tahoma, Verdana, Arial, sans-serif;
	font-size: 0.9em;
	background-color: #EEE;
	color: #333;
	border: 1px solid #DDD;

	-moz-border-radius: 4px;
	-webkit-border-radius: 4px;
	border-radius: 4px;
	padding: 0.2em;
	width: 14em;
}

.calendar .months {
	background-color: #F6AF3A;
	border: 1px solid #E78F08;
	-moz-border-radius: 4px;
	-webkit-border-radius: 4px;
	border-radius: 4px;
	color: #FFF;
	padding: 0.2em;
	text-align: center;
}

.calendar .prev-month,
.calendar .next-month {
	padding: 0;
}

.calendar .prev-month {
	float: left;
}

.calendar .next-month {
	float: right;
}

.calendar .current-month {
	margin: 0 auto;
}

.calendar .months .prev-month,
.calendar .months .next-month {
	color: #FFF;
	text-decoration: none;
	padding: 0 0.4em;
	-moz-border-radius: 4px;
	-webkit-border-radius: 4px;

	border-radius: 4px;
	cursor: pointer;
}



.calendar .months .prev-month:hover,
.calendar .months .next-month:hover {
	background-color: #FDF5CE;
	color: #C77405;
}

.calendar table {
	border-collapse: collapse;
	padding: 0;
	font-size: 0.8em;
	width: 100%;
}

.calendar th {
	text-align: center;
}

.calendar td {
	text-align: right;
	padding: 1px;
	width: 14.3%;
}

.calendar td span {
	display: block;
	color: #1C94C4;
	background-color: #F6F6F6;
	border: 1px solid #CCC;
	text-decoration: none;
	padding: 0.2em;
	cursor: pointer;
}

.calendar td span:hover {
	color: #C77405;
	background-color: #FDF5CE;
	border: 1px solid #FBCB09;
}

.calendar td.today span {
	background-color: #FFF0A5;
	border: 1px solid #FED22F;
	color: #363636;
}


#dir_box_premium {
background-color:#FFFF66;
padding-left:10px;
padding-top:10px;
padding-right:10px;
border:5px solid #cccccc;
margin-bottom:15px;
}

#dir_box_featured {
background-color:#faf6a3;
padding-left:10px;
padding-top:10px;
padding-right:10px;
border:1px solid #cccccc;
margin-bottom:15px;
min-height:160px
}

#dir_box_std{
background-color:#fff;
padding-left:10px;
padding-top:10px;
padding-right:10px;
border:1px solid #cccccc;
margin-bottom:15px;
min-height:130px
}


#dir_table {
float:left;
margin-left:0px;
margin-bottom:0px;
}

#dir_title{
	
Color:#4A7194;
font-size:18px;
text-align:left;

padding-bottom:10px;
}

#dir_desc{
height:70px;

font-size:12px;
}
#dir_contains{
float:left;
color:#d9831a;
position:relative;
top:0px;
}
#dir_contains hr{
color:#d9831a;
}


#dir_image{
	float:right;
	width: auto !important; 
	width: 10%;
	max-width: 10%;
	max-height:10%;
	margin-right:6px;
	margin-left:20px;
	margin-bottom:20px;
	position:relative;
	top:0px;
}

#dir_image_noshadow{
float:right;
        width: auto !important; 
        max-width: 255px;
        max-height:255px;
margin-right:6px;
margin-left:20px;
  left:20;
top:0px;
position:relative;
}

#dir_image_noshadow_std{
float:right;




        max-width:340px;
        max-height:10px;


top:0px;
position:relative;
}

#dir_image img{
height: 60px;
}

#dir_website{
font-size:15px;
}


#dir_moreinfo{
font-size:15px;
}
#dir_button_container{
float:right;
position:relative;
top:30px;
}

#dir_button_container_std{
float:right;
position:relative;
top:75px;
}



#dir_buttons{ height:37px; width:206px; margin: 0;  border:1px solid; border-radius:5px;} 
#dir_buttons > ul {list-style:inside none; padding:0; margin:0;} 
#dir_buttons > ul > li {list-style:inside none; padding:0; margin:0; float:left; display:block; position:relative;} 
#dir_buttons > ul > li > a{ outline:none; display:block; position:relative; padding:12px 20px; font:bold 13px/100% Arial, Helvetica, sans-serif; text-align:center; text-decoration:none; } 
#dir_buttons > ul > li:first-child > a{border-radius:5px 0 0 5px;} 
#dir_buttons > ul > li > a:after{ content:''; position:absolute; border-right:1px solid; top:-1px; bottom:-1px; right:-2px; } 
#dir_buttons ul li.has-sub:hover > a:after{top:0; bottom:0;} 
#dir_buttons > ul > li.has-sub > a:before{ content:''; position:absolute; top:18px; right:6px; border:5px solid transparent; border-top:5px solid #fff; } 
#dir_buttons > ul > li.has-sub:hover > a:before{top:19px;} 
#dir_buttons ul li.has-sub:hover > a{ background:#3f3f3f; border-color:#3f3f3f; padding-bottom:13px; padding-top:13px; top:-1px; } 
#dir_buttons ul li.has-sub:hover > ul, #cssmenu ul li.has-sub:hover > div{display:block;} 
#dir_buttons ul li.has-sub > a:hover{background:#3f3f3f; border-color:#3f3f3f;} 
#dir_buttonsul li > ul, #cssmenu ul li > div{ display:none; width:auto; position:absolute; top:38px; padding:10px 0; background:#3f3f3f; border-radius:0 0 5px 5px; } 
#dir_buttons ul li > ul{width:200px;} 
#dir_buttons ul li > ul li{display:block; list-style:inside none; padding:0; margin:0; position:relative;} 
#dir_buttons ul li > ul li a{ outline:none; display:block; position:relative; margin:0; padding:8px 20px; font:10pt Arial, Helvetica, sans-serif; color:#fff; text-decoration:none;  } 


#dir_buttons, #dir_buttons > ul > li > ul > li a:hover{ background:#333333; background:-moz-linear-gradient(top, #333333 0%, #222222 100%); background:-webkit-gradient(linear, left top, left bottom, color-stop(0%,#333333), color-stop(100%,#222222)); background:-webkit-linear-gradient(top, #333333 0%,#222222 100%); background:-o-linear-gradient(top, #333333 0%,#222222 100%); background:-ms-linear-gradient(top, #333333 0%,#222222 100%); background:linear-gradient(top, #333333 0%,#222222 100%); filter:progid:DXImageTransform.Microsoft.gradient( startColorstr='#333333', endColorstr='#222222',GradientType=0 ); } 
#dir_buttons{border-color:#000;} 
#dir_buttons > ul > li > a{border-right:1px solid #000; color:#fff;} 
#dir_buttons> ul > li > a:after{border-color:#444;} 
#cdir_buttons > ul > li > a:hover{background:#111;}









#directory_box {
background-color:#fff;
padding-left:10px;
padding-top:10px;
padding-right:10px;
border:1px solid #cccccc;
min-height: 230px;
}





#breadcrumbs{
font-size:18px;
padding-left:5px;
padding-bottom:5px;
position:relative;
}

#directory_phone{
font-size:20px;
text-align:center;
}

#directory_button_container{
float:right;
position:relative;
top:30px;
}

#directory_button_container_std{
float:right;
position:relative;
top:75px;
}

#directory_buttons{ height:37px; width:124px; margin: 0;  border:1px solid; border-radius:5px;} 
#directory_buttons > ul {list-style:inside none; padding:0; margin:0;} 
#directory_buttons > ul > li {list-style:inside none; padding:0; margin:0; float:left; display:block; position:relative;} 
#directory_buttons > ul > li > a{ outline:none; display:block; position:relative; padding:12px 20px; font:bold 13px/100% Arial, Helvetica, sans-serif; text-align:center; text-decoration:none; } 
#directory_buttons > ul > li:first-child > a{border-radius:5px 0 0 5px;} 
#directory_buttons > ul > li > a:after{ content:''; position:absolute; border-right:1px solid; top:-1px; bottom:-1px; right:-2px; } 
#directory_buttons ul li.has-sub:hover > a:after{top:0; bottom:0;} 
#directory_buttons > ul > li.has-sub > a:before{ content:''; position:absolute; top:18px; right:6px; border:5px solid transparent; border-top:5px solid #fff; } 
#directory_buttons > ul > li.has-sub:hover > a:before{top:19px;} 
#directory_buttons ul li.has-sub:hover > a{ background:#3f3f3f; border-color:#3f3f3f; padding-bottom:13px; padding-top:13px; top:-1px; } 
#directory_buttons ul li.has-sub:hover > ul, #cssmenu ul li.has-sub:hover > div{display:block;} 
#directory_buttons ul li.has-sub > a:hover{background:#3f3f3f; border-color:#3f3f3f;} 
#directory_buttonsul li > ul, #cssmenu ul li > div{ display:none; width:auto; position:absolute; top:38px; padding:10px 0; background:#3f3f3f; border-radius:0 0 5px 5px; } 
#directory_buttons ul li > ul{width:200px;} 
#directory_buttons ul li > ul li{display:block; list-style:inside none; padding:0; margin:0; position:relative;} 
#directory_buttons ul li > ul li a{ outline:none; display:block; position:relative; margin:0; padding:8px 20px; font:10pt Arial, Helvetica, sans-serif; color:#fff; text-decoration:none; } 


#directory_buttons, #dir_buttons > ul > li > ul > li a:hover{ background:#333333; background:-moz-linear-gradient(top, #333333 0%, #222222 100%); background:-webkit-gradient(linear, left top, left bottom, color-stop(0%,#333333), color-stop(100%,#222222)); background:-webkit-linear-gradient(top, #333333 0%,#222222 100%); background:-o-linear-gradient(top, #333333 0%,#222222 100%); background:-ms-linear-gradient(top, #333333 0%,#222222 100%); background:linear-gradient(top, #333333 0%,#222222 100%); filter:progid:DXImageTransform.Microsoft.gradient( startColorstr='#333333', endColorstr='#222222',GradientType=0 ); } 
#directory_buttons{border-color:#000;} 
#directory_buttons > ul > li > a{border-right:1px solid #000; color:#fff;} 
#directory_buttons> ul > li > a:after{border-color:#444;} 
#directory_buttons > ul > li > a:hover{background:#111;}

#job_contains{
font-size:10px;
}





div#main {
	width: 360px;
	margin: 200px auto 20px auto;
}
.title {
	line-height: 1.2em;
	position: relative;
	margin-left: 40px;
}
div.icon {
	margin-top: 4px;
	float: left;
	width: 31px;
	height: 30px;
	background-image: url(/media/images/magnify_2393.gif);
	background-repeat: no-repeat;
	-webkit-transition-property: background-position, color;
	-webkit-transition-duration: .2s, .1s;
	-webkit-transition-timing-function: linear, linear;
	-moz-transition-property: background-position, color;
	-moz-transition-duration: .2s, .1s;
	-ms-transition-duration: .2s, .1s;
	-ms-transition-timing-property: linear, linear;
	-o-transition-property: background-position, color;
	-o-transition-duration: .2s, .1s;
	-o-transition-timing-property: linear, linear;
	transition-property: background-position, color;
	transition-duration: .2s, .1s;
	transition-timing-property: linear, linear;
}
div.icon:hover {
	background-position: 0px -30px;
	cursor: pointer;
}











































#signup_form{
  z-index:0 !important; 
  position:relative;
}

/*------------------------ Search bar ---------------------------------------------------------------------*/
#results_holder{
 height:400px;
  overflow:scroll;
}
#searchForm {
	float: none;
	display: inline-block;
	margin-top: 0;
	vertical-align: middle;
	padding: 7px 10px;
}

#searchForm--secondary {
	@extend #searchForm;
	z-index: 1;
}

#searchForm fieldset {
    position:relative;
    display:block;
	width: auto;
}

div.input {
    width:120px;
    padding:6px 15px 6px 8px;
    box-shadow:inset 0 1px 2px rgba(0,0,0,0.08)!important;
    float:right;
	line-height: 0px;
	background: #F5F5F5;
}

div.input--secondary {
	@extend div.input;
	background: #f5f5f5;
	padding: 6px 8px;
	
	@include bp(mobile) {
		float: none;
	}
}

div.input.focus {
	background: #F5F5F5;
}
input#search {
    width:100%;
    border:0;
    background:transparent;
    height:16px;
	text-transform:uppercase;
	color: #886d97;
    font-weight: 400;
    
	@include placeholder {
		color: #886d97;
		font-weight: 400;
		font-style: italic;
		
		&:focus {
			color: #886d97 !important;
		}
	}
}

input#search:focus {
	color: #3b3b3b;
	border: 1px solid #36a2d2;
}

.input--secondary {
	width: auto;
}

input#search--secondary {
	@extend input#search;
	font-size: em(12);
	font-family: 'Open Sans Semi-Bold', sans-serif;
	
	&:focus {
		outline: 2px solid #36a2d2;
		outline-offset: 6px;
		border: none;
		@include bp(mobile) {
			width: 100%;
		}
	}
}

/*------------------------ Search  (ajax) ---------------------------------------------------------------------*/
#resultsContainer {
	position: absolute;
	z-index: 2;
	right: 0;
	top: 130px;
}

#results_text {
	width: 670px;
	background: #DDDDDD;
	padding: 14px 20px 0 20px;
	height: 38px;
}
.cross_box {
 	position:relative;
	background: url(/media/images/cross_2392.png) 0 0 no-repeat;
	width: 16px;
	height: 16px;
	margin-top: 4px;
	float: right;
	cursor: pointer;
}
.cross_box:hover, .cross_box:active, .cross_box:focus { /* this group of selectors will give a keyboard navigator the same hover experience as the person using a mouse. */
	background-position: 0 -17px;
}
#search_string {
	color:#b741bb;
	font-family: 'Open Sans', sans-serif;
	font-weight: 600;
}
.highlight{
	color:#b741bb;
}
.highlight_catch { 
	font-weight: bold; 


	color: #bf56c3;
}
ul#results {
	background-color:#fff;
	list-style: none;
	width: 670px;
	max-height :340px;
	overflow-y:scroll;
	padding: 20px;
}
ul#results li {
	padding: 12px 12px 5px 12px;
	cursor: pointer;
	border-bottom: solid 1px #f3f3f3;	
	transition: background-color .3s ease-in-out;
	-moz-transition: background-color .3s ease-in-out;
	-webkit-transition: background-color .3s ease-in-out;
	@include clearfix;
}
ul#results li:hover {
	background-color: #f3f3f3;
}
ul#results li:first-child {
	border-top: none;
}
ul#results li:last-child {
	margin-bottom: 20px;
}
ul#results li h3, ul#results li h4 {
	transition: color .3s ease-in-out;
	-moz-transition: color .3s ease-in-out;
	-webkit-transition: color .3s ease-in-out;
	color: #616161;
	line-height: 1.2em;
}
ul#results li:hover h3, ul#results li:hover h4  {
	color: #3b3b3b;
}
#search_title{
	/*width:525px;
	transition: color .3s ease-in-out;
	-moz-transition: color .3s ease-in-out;
	-webkit-transition: color .3s ease-in-out;
	color: #4A7194;
	line-height: 1.2em;
	font-size:16px;*/
}

.results__text{
	padding-left: 120px;
}

#overlayOLD {
	z-index:0 !important;
	background:rgba(0,0,0,0.3);
	display:none;
	width: 100%;
	height: 100%;
	position:absolute; top:0; left:0; bottom: 0; 
}
#overlay {
	position: fixed;
	top: 0;
	left: 0;
	right: 0px;
	bottom: 0px;
	background: #000;
	
	/* IE 8 */
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
	/* IE 5-7 */
	filter: alpha(opacity=50);
	/* Netscape */
	-moz-opacity: 0.5;
	/* Safari 1.x */
	-khtml-opacity: 0.5;
	/* Good browsers */
	opacity: 0.5;
	
	width: 100%;
	z-index: 0 !important;
	display:none;
	zoom: 1;
	z-index: 2 !important;
}

.filter select {
	font-size:15px;
    padding:6px;
    margin: 5px 10px 15px 10px;
    -webkit-border-radius:4px;
    -moz-border-radius:4px;
    border-radius:4px;
    background: #f8f8f8;
    color:#666;
    border: 1px solid #ccc;
    outline:none;
    display: inline-block;
    -webkit-appearance:none;
    -moz-appearance:none;
    appearance:none;
    cursor:pointer;
}
#sponsored_box {
	height:81px; 
	width: 670px;
	background:url(/media/images/technology-header_2391.png) no-repeat 0 0 #fff;
	margin: 10px 0 25px 0
}
.bigheading {
	font-size: 40px;
}
.sponsortext {
	font-size: 26px;	
}

/*------------------------ 3 column Content Area (magazine back issues) ---------------------------------------------------------------------*/
.content_grid{
	height:1850px;
    overflow:hidden;
  }
.grid_4col {
	padding: 0 20px;
	/*background: #0f0;*/
	float: left;
	height: 900px;
	border-right: 1px dotted #e6e6e6;
}
.grid_first_col {
	padding-left: 0!important;
}
.grid_last_col {
	border-right: none!important;
	padding-right: 0!important;
}
.mag_shot {
	background: #0f0;
	width: 202px;
	height:296px;
	margin: 20px 0 20px 0;
}

#backissue_title{
	margin-bottom: 20px;
	line-height: 12px;
	font-family: 'Open Sans', sans-serif;
	font-weight: 400;
	font-size: 16px;
	color: #886d97;
	height: 17px; 
	width: 202px;
	text-align: center;
}




/*------------------------ CONTACT US ---------------------------------------------------------------------*/
#contact_us #story_intro_box .story_headline {
	font-family: 'Open Sans', sans-serif;
	font-weight: 300;
	font-size:24px;
	text-align:left;
	line-height: 1.6em;
	vertical-align: baseline;
	letter-spacing: -0.6px;
	word-spacing: 0px;
}
.story_ruled_singleline {
	padding-top: 9px;
	border-top: 1px solid #f0f0f0;
	border-bottom: 1px solid #f0f0f0;
	margin-bottom: 30px;
}
.story_ruled_singleline p {
	margin-bottom: 9px;
}
#contact_us #story .sub_headline_main {
	font-size: 16px;
}
#contact_phone_no {
	color: #886d97;
	font-size: 16px;
	font-family: 'Open Sans', sans-serif;
	font-weight: 600;
}
#view_large_map {
	margin: 5px 0 0 0;
	color: #555;
}
.headline_big {
	font-family: 'Open Sans', sans-serif;
	font-weight: 300;
	font-size:52px;
	text-align:center;
	line-height: 1.4em;
	vertical-align: baseline;
	letter-spacing: -0.6px;
	word-spacing: 0px;
	padding-bottom: 24px;
	border-bottom: 1px solid #f0f0f0;
	margin-top: 60px;
}
#google_map_holder {
	width: 670px;
	height: 368px;
	background:url(/media/images/google-map-dummy_2361.jpg) no-repeat 0 0 #ccc;
	margin-bottom: 40px;
}

/*------------------------ 404 ERROR PAGE ---------------------------------------------------------------------*/
#error_page_box {
	position:relative;
	width: 700px;
	height: 400px;
	margin: 0 auto; /* the auto value on the sides, coupled with the width, centers the layout */
	padding: 80px;
	border: 1px solid #ccc;
	text-align: center!important;
}
#logo_main_error {
	position: relative;
	background: url('/media/images/logo-relocate-error_2360.png') 0 0 #0f0;
	width: 200px;
	height: 167px;
	margin: 29px 0 0 0;
}
.logo_main_404 {
	margin: 0 auto;
}
.error_page_para {
	margin-top: 30px;
	padding-left: -60px;
	font-size: 18px;
}

/*======================= DIRECTORY PAGE ===================================================================================*/
/*------------------------ Directory Filter Strip --------------------------------------------------*/
.filter_strip { margin: 11px 0 30px 0; }

.filter_strip_lbl {
	margin-right: 10px;
	font-family: 'Open Sans', sans-serif;
	font-weight: 600;
	font-size:13px;
	line-height: 1.5em (19.5px);
	text-transform: uppercase;
	text-align:left;
}
.filter_strip_block {
	padding: 6px 12px 0 12px;
	height: 32px;
	background-color: #c1c1c1;
	border-right: 1px solid #e3e3e3;
}
.filter_btn {
	padding: 0 12px;
	height: 38px;
	border: none;
}
.filter_btn_go { 
	margin-left: 5px; 
	cursor: pointer;
}

/*------------------------ Premium Directory Listing --------------------------------------------------*/
#directory_premium_box {
	position: relative;
	border: 10px solid #404040;
	margin-bottom: 25px;
}
#directory_premium_top {
	position: relative;
	padding: 27px 27px 0 27px;
	margin-bottom: 17px;
}
#directory_premium_logo {
	position: relative;
	width: 275px;
	height: 210px;
	border-right: 1px solid #c1c1c1;
	float: left;
  overflow:hidden;
	
}
#directory_premium_image {
	position: relative;
	width: 220px;
	height: 120px;
	background: #e1e1e1;
	float: left;
	text-align: center;
	padding-top: 90px;
}
#directory_premium_text {
	position: relative;
	width: 336px;
	height: 210px;
	padding: 0 27px 0 27px;
	float: left;
}
.directory_premium_headline {
	font-family: open_sanssemibold, sans-serif;
	font-size: 32px;
	text-transform: uppercase;
	text-align: left;
	padding-bottom: 15px;
	line-height: 1.1em;
	vertical-align: baseline;
	letter-spacing: -0.6px;
	word-spacing: 0px;
}
.directory_featured_footer {
	position: relative;
	margin: 0 27px 0 27px;
	padding: 14px 0;
	border-top: 1px dotted #d7d7d7;
	@include clearfix;
}
.block_button {
	font-size: 11px;
	color: #fff;
	text-transform: uppercase;
	font-family: open_sanssemibold, sans-serif!important;
	padding: 5px 12px 5px 12px;
	background-color: #886d97;
	float: right;
	
	border-radius: 2px;
	-webkit-border-radius: 2px;
	-moz-border-radius: 2px;
	-ms-border-radius: 2px;
}
.block_button:hover, .block_button:active, .block_button:focus {
	color: #fff;
	background-color: #9b84a8;
     -webkit-transition: background-color 200ms linear;
     -moz-transition: background-color 200ms linear;
     -o-transition: background-color 200ms linear;
     -ms-transition: background-color 200ms linear;
     transition: background-color 200ms linear;
}
.directory_footer_url {
	float: right;
	margin: 0 20px 0 5px;
}
.roundel_social {
	position:relative;
	width: 24px;
	height: 24px;
	margin-right: 10px;
	text-indent: -9000px;
	float: right;
}

.roundel_facebook {
	display: block;
	width: 24px;
	height: 24px;
}

.roundel_twitter {
	display: block;
	width: 24px;
	height: 24px;
}

.roundel_linkedin {
	display: block;
	width: 24px;
	height: 24px;
}

.roundel_googleplus {
	display: block;
	width: 24px;
	height: 24px;
}

.roundel_facebook { background: url(/media/images/roundel-small-facebook_2359.gif) 0 -24px no-repeat; }
.roundel_twitter { background: url(/media/images/default_24027.gif) 0 -24px no-repeat; }
.roundel_linkedin { background: url(/media/images/roundel-small-linkedin_2357.gif) 0 -24px no-repeat; }
.roundel_googleplus { background: url(/media/images/roundel-small-googleplus_2356.gif) 0 -24px no-repeat; }
.roundel_youtube { background: url(/media/images/roundel-small-youtube_2355.gif) 0 -24px no-repeat; }
.roundel_rss { background: url(/media/images/roundel-small-rss_2354.gif) 0 -24px no-repeat; }
.roundel_instagram { background-image: url(/media/images/instagram-constructed_10228.png); background-size: cover; background-position: center; };

.directory_expanded_social_box .roundel_social:hover, .directory_expanded_social_box .roundel_social:active, .directory_expanded_social_box .roundel_social:focus {
	background-position: 0px -24px!important;
}
/*------------------------ Featured Directory Listing --------------------------------------------------*/
.directory_featured_box {
	position: relative;
	border: 7px solid #c1c1c1;
	margin-bottom: 25px;
}
.dir_feat_box {/*NEW BUILD*/
	position: relative;
	border: 7px solid #c1c1c1;
	margin-bottom: 25px;
	padding: 20px 27px 14px 27px;
}
.zebra_stripe { background-color: #f4f4f4;}

.directory_featured_top {
	position: relative;
	padding: 20px 27px 0 27px;
	margin-bottom: 17px;
	display: block;
}
.directory_featured_logo {
	position: relative;
	width: 175px;
	height: 110px;
	border-right: 1px solid #c1c1c1;
	float: left;
	overflow: hidden;
	position: relative;
	width: 160px;
	height: 110px;
	border-right: 1px solid #c1c1c1;
	float: left;
	overflow: hidden;
	padding-right: 15px;
	text-align: center;
}
.directory_featured_text {
	position: relative;
	width: 657px;
	height: 110px;
	padding: 0 27px 0 27px;
	float: left;
}
.dir_feat_logo {/*NEW BUILD*/
	position: relative;
	height: 110px;
	border-right: 1px solid #c1c1c1;
	overflow:hidden;
	
	width: 17.3%;
	padding-right: 2.3%;
	margin-right: 3%;
}
.dir_feat_text {/*NEW BUILD*/
	position: relative;
	width: 77.65%;
  	height: 110px;
}
.directory_featured_headline {
	font-family: open_sanssemibold, sans-serif;
	font-size: 24px;
	text-transform: uppercase;
	text-align: left;
	padding-bottom: 15px;
	line-height: 1.1em;
	vertical-align: baseline;
	letter-spacing: -0.6px;
	word-spacing: 0px;
}
.dir_footer {
	position: relative;
	margin-top: 17px 0 0 0;
	padding: 14px 0 0 0;
	border-top: 1px dotted #d7d7d7;
}
.dir_resp_test {
	width: 960px;
	margin: 19px 0 0 0;
	/*background: #0F3;*/
	min-height: 800px;
}
.wrap_content_resp_test {
	width: 960px;
	margin: 0 auto; /* the auto value on the sides, coupled with the width, centers the layout */
	z-index: 100;
}
.soc_media_float {
	display: block;
	float: right;
}
/*------------------------ Standard Directory Listing --------------------------------------------------*/
.rule_grey {
	height: 1px;
	background-color: #c1c1c1;
	clear: both;
}

.directory_standard_image,.directory_featured_image{
	display: inline;
}

.directory_standard_box {
	position: relative;
	border-bottom: 1px solid #c1c1c1;
	&:nth-child(even){
		background-color: #f4f4f4;
	}
}
.directory_standard_top {
	position: relative;
	padding: 16px 27px 0 27px;
	margin-bottom: 14px;
	display: block;
}
.directory_standard_logo {
	position: relative;
	width: 125px;
	height: 85px;
	border-right: 1px solid #c1c1c1;
	float: left;
	overflow: hidden;
	padding-right: 15px;
	text-align: center;
	margin-bottom: 20px;
}
.directory_standard_text {
	position: relative;
	padding: 0 27px 0 20px;
	margin-left: 141px;
}
.directory_standard_headline {
	font-family: open_sanssemibold, sans-serif;
	font-size: 15px;
	text-transform: uppercase;
	text-align: left;
	padding-bottom: 15px;
	line-height: 1.1em;
	vertical-align: baseline;
	letter-spacing: -0.6px;
	word-spacing: 0px;
}
.directory_standard_footer {
	position: relative;
	margin: 0 27px 0 27px;
	padding: 8px 0 10px 0;
	border-top: 1px dotted #d7d7d7;
	@include clearfix;
}
/*------------------------ Expanded Directory Listing --------------------------------------------------*/
#directory_expanded_box {
	position: relative;
	border: 7px solid #c1c1c1;
	margin-bottom: 25px;
	padding: 27px;
}
#directory_expanded_col1 {
	position: relative;
	float: left;
	width: 200px;
	padding-right: 20px;
}
#directory_expanded_col2 {
	position: relative;
	float: right;
	width: 671px;
	border-left: 1px solid #c1c1c1;
}
#directory_expanded_logo {
	display: block;
	position: relative;
	width: 201px;
  margin-bottom:20px;
	overflow:hidden;
}
#directory_expanded_text {
	position: relative;
	padding: 0 0 0 27px;
	float: left;
}
.directory_expanded_headline {
	font-family: open_sanssemibold, sans-serif;
	font-size: 28px;
	text-transform: uppercase;
	text-align: left;
	padding-bottom: 15px;
	margin-bottom: 16px;
	line-height: 1.1em;
	vertical-align: baseline;
	letter-spacing: -0.6px;
	word-spacing: 0px;
	border-bottom: 1px dotted #d7d7d7;
}
.directory_expanded_url {
	display: block;
	position: relative;
	margin: 0;
	padding: 4px 0 6px 0;
	text-align: center;
	border-top: 1px dotted #d7d7d7;
	border-bottom: 1px dotted #d7d7d7;
	width: 100%;
  font-size:0.8em;
}
#directory_expanded_social_box {
	padding: 14px 0;
	border-bottom: 1px dotted #d7d7d7;
}
.directory_expanded_contact {
	padding: 20px 0 12px 0;
	border-bottom: 1px dotted #d7d7d7;
}
.directory_expanded_contact p { margin-bottom: 8px; }
.directory_expanded_contact a { font-weight: normal; }

.directory_expanded_mainpic {
	width: 640px;
	height: 294px;
	background: url(/media/images/directory-big-pic-dummy_2353.jpg) 0 0 no-repeat #f1f1f1;
	margin: 24px 0;
}
#directory_expanded_box ol {
	padding-left: 0;
	margin-left: 20px;
	font-family: sans-serif;
	font-size: 12px;
	line-height: 1.8em;
	vertical-align: baseline;
	letter-spacing: normal;
	word-spacing: 0px;
}
#directory_expanded_box ul {
	padding-left: 0;
	margin-left: 0;
	font-family: sans-serif;
	font-size: 12px;
	line-height: 1.8em;
	vertical-align: baseline;
	letter-spacing: normal;
	word-spacing: 0px;
}

#directory_expanded_box ul li { 
	background:url(/media/images/bullet-arrow_20119.png) no-repeat 0 6px;
	padding-left: 18px;
}
.directory_expanded_map {
	padding: 28px 0 18px 0;
	border-bottom: 1px dotted #d7d7d7;
}
.video_box {
	margin: 10px 0;
}
.directory_expanded_thumbnail {
	width: 91px;
	height: 91px;
	float: left;
	background: url(/media/images/directory-thumbnail-dummy_2352.jpg) 0 0 no-repeat #f1f1f1;
	margin: 18px 18px 0 0;
	
	border: none;
	opacity: 1;
   transition: opacity .25s ease-in-out;
   -moz-transition: opacity .25s ease-in-out;
   -webkit-transition: opacity .25s ease-in-out;
}
.directory_expanded_thumbnail:hover, .directory_expanded_thumbnail:active, .directory_expanded_thumbnail:focus {
	/* IE 8 */
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
	/* IE 5-7 */
	filter: alpha(opacity=80);
	/* Netscape */
	-moz-opacity: 0.8;
	/* Safari 1.x */
	-khtml-opacity: 0.8;
	/* Good browsers */
	opacity: 0.8;
}
.no_margin_right { margin-right: 0; }
.no_margin_bottom { margin-bottom: 0px; }

.backto_link { text-align: right; }


/* --------------- Fade div classes for Javascript fade in/out ------------------------------------------------------------------- */
.fadediv {
	position: absolute;
	top: 0px;
	left: 0px;
	z-index: 1;
}
.fadediv_vis {
	z-index: 2;
}
/* --------------- Our Ad Space ------------------------------------------------------------------- */
.our_adspace_cont {
	position: relative;
	top: 0px;
	left: 0px;
	width: 250px;
	height: 400px;
	margin-bottom: 30px;
}
.our_adspace {
	position: absolute;
	width: 250px;
	height: 400px;
}
.our_ad_careers #frame1 { background: url(/media/images/careers-banner-frame1_2351.png) no-repeat 0 0; }
.our_ad_careers #frame2 { background: url(/media/images/careers-banner-frame2_2350.png) no-repeat 0 0; }
.our_ad_hrmob #frame3 { background: url(/media/images/hrmob-mpu-frm1_2349.png) no-repeat 0 0; }
.our_ad_hrmob #frame4 { background: url(/media/images/hrmob-mpu-frm2_2348.png) no-repeat 0 0; }
.our_ad_party #frame5 { background: url(/media/images/charity-party-banner-frame1_2347.png) no-repeat 0 0; }
.our_ad_party #frame6 { background: url(/media/images/charity-party-banner-frame2_2346.png) no-repeat 0 0; }
.our_ad_awards #frame7 { background: url(/media/images/awards2013-bnr-port-frm1_2724.png) no-repeat 0 0; }
.our_ad_awards #frame8 { background: url(/media/images/awards2013-bnr-port-frm2_2344.png) no-repeat 0 0; }
.our_ad_awards #frame9 { background: url(/media/images/awards2013-bnr-port-frm3_2343.png) no-repeat 0 0; }
.our_ad_awards #frame10{ background: url(/media/images/awards2013-bnr-port-frm4_2342.png) no-repeat 0 0; }

/* --------------- SIDE NAV OLD ------------------------------------------------------------------- */
ul.sidenav_list {
	position:relative;
	background:#fff;
	width:250px;
	margin:auto;
	padding:0;
	list-style: none;
	overflow:hidden;
	margin-bottom: 40px;
}
.sidenav_list_header{
  background-color:#ccc;
  
  }
.sidenav_list li a {
	width:250px;
	padding-left:12px;
	height:46px;
	line-height:46px;
	display:block;
	overflow:hidden;
	position:relative;
	font-family: 'Open Sans', sans-serif;
	font-weight: 400;
	text-decoration:none;
	text-transform:uppercase;
	font-size:12px;
	color: #242424;
	box-sizing:border-box;
	-moz-box-sizing:border-box;
	-webkit-box-sizing:border-box;
	border-bottom: 1px solid #efefef;
	
	-webkit-transition:all 0.2s linear;
	-moz-transition:all 0.2s linear;
	-o-transition:all 0.2s linear;
	transition:all 0.2s linear;			
}
.sidenav_list li a:hover { background:#efefef; }

.sidenav_list li.selected a {
	background:#886D97;
	color:#fff;
	border-bottom: #886D97;
}
.side_nav_arrow {
	float: right;
	margin-right: 10px;
	transform: scale(0.7,1);
	-ms-transform: scale(0.7,1); /* IE 9 */
	-webkit-transform: scale(0.7,1); /* Safari and Chrome */
	-moz-transform: scale(0.7,1); /* Firefox */
	-o-transform: scale(0.7,1); /* Opera */
}
.sidenav_list li a .side_nav_arrow { color:#fff; }

/* --------------- SIDE NAV ------------------------------------------------------------------- */
ul.sidenav_list {
	position:relative;
	background:#fff;
	margin:auto;
	padding:0;
	list-style: none;
	overflow:hidden;
	margin-bottom: 25px;
}
.sidenav_list_header{
  background-color:#ccc;
}
.sidenav_title {
	width:250px;
	padding:0 12px;
	height:46px;
	line-height:46px;
	display:block;
	overflow:hidden;
	position:relative;
	font-family: 'Open Sans', sans-serif;
	font-weight: 400;
	text-decoration:none;
	text-transform:uppercase;
	font-size:12px;
	color: #242424;
	background-color: #d7cedc;
	box-sizing:border-box;
	-moz-box-sizing:border-box;
	-webkit-box-sizing:border-box;
	border-bottom: 1px solid #fff;
	
	-webkit-transition:all 0.2s linear;
	-moz-transition:all 0.2s linear;
	-o-transition:all 0.2s linear;
	transition:all 0.2s linear;		
	cursor: pointer;
  margin-bottom: 5px;
}
.sidenav_title_sel {
	width:250px;
	padding:0 12px;
	height:46px;
	line-height:46px;
	display:block;
	overflow:hidden;
	position:relative;
	font-family: 'Open Sans', sans-serif;
	font-weight: 400;
	text-decoration:none;
	text-transform:uppercase;
	font-size:12px;
	color: #fff;
	background-color: #5e367b;
	box-sizing:border-box;
	-moz-box-sizing:border-box;
	-webkit-box-sizing:border-box;
	border-bottom: 1px solid #fff;
	
	-webkit-transition:all 0.2s linear;
	-moz-transition:all 0.2s linear;
	-o-transition:all 0.2s linear;
	transition:all 0.2s linear;
	cursor: pointer;
  margin-bottom: 5px;
}
.sidenav_title_sel:hover { background-color:#522872; }
.sidenav_title:hover { background:#c2b4ca; }

.sidenav_list li a {
	margin-left:13px;
	padding:0 12px;
	height:38px;
	line-height:38px;
	display:block;
	overflow:hidden;
	position:relative;
	font-family: 'Open Sans', sans-serif;
	font-weight: 400;
	text-decoration:none;
	text-transform:uppercase;
	font-size:12px;
	color: #242424;
	box-sizing:border-box;
	-moz-box-sizing:border-box;
	-webkit-box-sizing:border-box;
	border-bottom: 1px solid #efefef;
	
	-webkit-transition:all 0.2s linear;
	-moz-transition:all 0.2s linear;
	-o-transition:all 0.2s linear;
	transition:all 0.2s linear;			
}
.sidenav_list li a:hover { background:#efefef; }

.sidenav_list li.selected a {
	background:#886D97;
	color:#fff;
	border-bottom: #886D97;
}
.side_nav_arrow {
	float: right;
	transform: scale(0.7,1);
	-ms-transform: scale(0.7,1); /* IE 9 */
	-webkit-transform: scale(0.7,1); /* Safari and Chrome */
	-moz-transform: scale(0.7,1); /* Firefox */
	-o-transform: scale(0.7,1); /* Opera */
}
.side_nav_arrow_updown {
	float: right;
	transform: scale(1, 0.7);
	-ms-transform: scale(1, 0.7); /* IE 9 */
	-webkit-transform: scale(1, 0.7); /* Safari and Chrome */
	-moz-transform: scale(1, 0.7); /* Firefox */
	-o-transform: scale(1, 0.7); /* Opera */
}
.sidenav_list li a .side_nav_arrow { color:#fff; }

.sidenav_heading_h3 {
	position: relative;
	font-family: 'Open Sans', sans-serif;
	font-size: 20px;
	font-weight: 400;
	padding: 30px 0 24px 0;
	line-height: 0;
	color: #252525;
	box-sizing:border-box;
	-moz-box-sizing:border-box;
	-webkit-box-sizing:border-box;
}


/* --------------- AWARDS PAGES ------------------------------------------------------------------- */

.awards_header{
  background-color:#252525;
    color:#fff;
  }


.hide_background { background-position: 0 200px; }

.awards_feat_text {
	border-top: 1px solid #001323;
	border-bottom: 1px solid #001323;
	margin-top: 18px;
	padding: 15px 0;
	font-size: 18px;
}
.awards_cat_box {
	background-color: #7f909a;
	width: 550px;
	margin: 30px 0;
	padding: 20px 30px 30px 30px;
}
.awards_cat_title {
	text-indent: -9999px;
	width: 486px;
	height: 36px;
	background: url(/media/images/awards-cat-title_2335.png) 0 0 no-repeat;
	margin-bottom: 10px;
}
.awards_cat_entry_box {
	width: 100%;
	padding: 15px 0;
	border-bottom: 1px solid #afb0b0;
}
.awards_cat_entry {
	font-family: 'Open Sans', sans-serif;
	font-weight: 400;
	color: #fff;
	font-size: 16px;
	float: left;
}
.awards_cat_entry p {
	margin: 6px 0 0 0;
	padding: 0;
}
.awards_grey_box {
	display: inline-block;
	background-color: #f4f4f5;
	margin: 30px 0;
	padding: 20px 25px;
}
.awards_bespoke_margin { margin: 10px 0 25px 0; }

.awards_purple_text {
	color: #49176d;
	font-family: 'Open Sans', sans-serif;
	font-size: 18px;
	margin: 0 3px;
	font-weight: 600;
}
.awards_grey_box_small_font { font-size: 14px; }

.awards_new_small {
	width: 55px;
	height: 31px;
	background: url(/media/images/awards-new-small_2334.png) 0 0 no-repeat;
	text-indent: -9999px;
	float: left;
	margin: -5px 0 0 5px;
}
.awards_sponsor_holder {
	height: 49px;
	background: #fff;
	float: right;
}
.awards_sponsor_holder_expanding {
	height: 49px;
	background: #fff;
	float: right;
	margin: 1px 15px 0 0;
}
.awards_inset_para {
	padding-left: 150px;
	background: url(/media/images/awards-new-large_2333.png) 0 4px no-repeat;
}
.content_awards #contact_phone_no {
	color: #886d97;
	font-size: 12px;
	font-family: 'Open Sans', sans-serif;
	font-weight: 600;
}

.content_awards h2 .no_margin {
	margin: 0!important;
}
.content_awards h3 {
	position: relative;
	display: block;
	font-family: 'Open Sans', sans-serif;
	font-size: 14px;
	font-weight: 600;
	padding: 10px 0;
	line-height: 1.6em;
	color: #4A7194;
	-webkit-transition: all .3s ease;
	-moz-transition: all .3s ease;
	-o-transition: all .3s ease;
	-ms-transition: all .3s ease;
	transition: all .3s ease;
}
.btn_content_acc:hover h3, .btn_content_acc:active h3, .btn_content_acc:focus h3 { /* this group of selectors will give a keyboard navigator the same hover experience as the person using a mouse. */
	color: #49176D;
}
.btn_content_acc:hover .section_header, .btn_content_acc:active .section_header, .btn_content_acc:focus .section_header { /* this group of selectors will give a keyboard navigator the same hover experience as the person using a mouse. */
	background-color: #ccc;
}
.faqs_holder { margin: 0 0 42px 0; }

.faq_block_first {
	border-top: 1px solid #dfdfdf;
	border-bottom: 1px solid #dfdfdf;
}
.faq_block {
	border-bottom: 1px solid #dfdfdf;
}
.expander_btn {
	cursor: pointer;
}
.expander_content {
	display: none;
}
.expander_content_hid_init {
	visibility: hidden;
}
.expander_content_disp_none {
	visibility: visible;
	display: none;
}
.accordionButton {	
	cursor: pointer;
}
.accordionContent {	
	display: none;
}
.accordContOpen {
	visibility: visible;
}
.plus_minus_btn {
	background: url(/media/images/btn-plus-minus_2332.gif) 0 0 no-repeat;
}
.plus_minus_btn_rvrs {
	background-position: 0 -26px;
}
.accordionContent_sidenav {	
	display: none;
}
.accordion_selected {color: #49176D;}

.faqs_content_acc {
	padding: 10px 0;
}
.content_awards .section_header_large {
	margin-bottom: 5px;
}
.btn_content_acc .section_header {
	float: right;
	background-color: #fff;
	font-size: 10px;
	padding: 3px 6px 3px 6px;
	margin-top: 11px;
	-webkit-transition: all .3s ease;
	-moz-transition: all .3s ease;
	-o-transition: all .3s ease;
	-ms-transition: all .3s ease;
	transition: all .3s ease;
}
.box_border_thick {
	border: 7px solid #cfcfcf;
	padding: 12px;
}
.listing_dotted_border {
	border-bottom: 1px dotted #cfcfcf;
	padding: 28px 0;
}

/* --------------- Awards Categories Accordion ------------------------------------------------------------------- */
.awards_cat_box_acc {
	width: 100%;
	margin: 30px 0;
}
.awards_cat_title_acc {
	text-indent: -9999px;
	width: 486px;
	height: 36px;
	background: url(/media/images/awards-cat-title_2335.png) 0 0 no-repeat;
	margin-bottom: 10px;
}
.awards_cat_entry_box_acc {
	padding: 15px 15px 15px 25px;
	background-color: #8a9ca7;
	-webkit-transition: all .3s ease;
	-moz-transition: all .3s ease;
	-o-transition: all .3s ease;
	-ms-transition: all .3s ease;
	transition: all .3s ease;
}
.awards_cat_entry_acc {
	font-family: 'Open Sans', sans-serif;
	font-weight: 400;
	color: #fff;
	font-size: 20px;
	float: left;
}
.awards_cat_entry_acc a{
	color:#fff !important;	
}
.awards_cat_entry_box_acc_h1 {
	font-family: 'Open Sans', sans-serif;
	font-size: 26px;
	font-weight: 600;
	line-height: 1.3em;
	margin: 0 0 10px 0;
	width: 190px;
}
.awards_cat_entry_box_acc_h3 {
	margin: -16px 0 2px 0;
}
.awards_cat_entry_box_acc p {
	margin: 3px 0 0 0;
	padding: 0;
	font-size: 14px;
	color: #242424;
}
.awards_cat_content_acc {
	background-color: #f4f4f5!important;
	padding: 25px;
}
.awards_cat_entry_box_acc .section_header {
	float: right;
	margin-top: 1px;
	-webkit-transition: all .3s ease;
	-moz-transition: all .3s ease;
	-o-transition: all .3s ease;
	-ms-transition: all .3s ease;
	transition: all .3s ease;
}
.awards_cat_entry_box_acc:hover .section_header, .awards_cat_entry_box_acc:active .section_header, .awards_cat_entry_box_acc:focus .section_header { /* this group of selectors will give a keyboard navigator the same hover experience as the person using a mouse. */
	background-color: #9374a4;
}
.awards_cat_entry_box_acc:hover, .awards_cat_entry_box_acc:active, .awards_cat_entry_box_acc:focus { /* this group of selectors will give a keyboard navigator the same hover experience as the person using a mouse. */
	background-color: #7f909a;
}
/* --------------- Awards Winners Accordion ------------------------------------------------------------------- */
.awards_winner_box {
	width: 300px;
	padding: 24px;
	border: 7px solid #8f8b93;
	float:left;
}
.awards_winner_box_full_width {
	width: 558px;
	padding: 24px 24px 10px 24px;
	border: 7px solid #8f8b93;
	float:left;
}
.awards_winner_sub_box {
	width: 200px;
	padding-left: 30px;
	float:left;
}
.awards_winner_rndl {
	width: 50px;
	height: 50px;
	margin: -4px 10px 0 0;
	background:url(/media/images/awards-winner-roundel_2331.png) no-repeat 0 0;
	text-indent: -9999px;
	float:left;
}
.awards_winner_extra_box .awards_winner_rndl {
	width: 50px;
	height: 50px;
	margin: 0 10px;
	background:url(/media/images/awards-winner-roundel_2331.png) no-repeat 0 0;
	text-indent: -9999px;
	float:left;
}
.awards_winner_imgholder {
	width: 100%;
	padding-top: 22px;
	margin-top: 6px;
	border-top: 1px dotted #afafb0;
}
.awards_winner_h2 {
	margin: -10px 0 10px 0!important;
}
.awards_winner_extra_h2 {
	margin: -20px 0 -10px 0!important;
}
.awards_winner_extra_box {
	border-bottom: 1px dotted #afafb0;
	padding: 15px 0;
}
.awards_winner_extra_box_first {
	border-top: 1px dotted #afafb0;
	margin-top: 25px;
}
.awards_jdge_comm_wnr_box {
	border-top: 1px dotted #afafb0;
	padding: 15px 0 11px 0;
}
.awards_jdge_comm_wnr_box_first {
	padding: 0 0 11px 0;
}
.awards_cat_entry_box_acc_h2 {
	font-family: 'Open Sans', sans-serif;
	font-size: 17px;
	font-weight: 600;
	line-height: 1.3em;
	margin: 0!important;
}
.awards_judge_comm_winner_h2 {
	margin: -5px 0 10px 0!important;
}
.para_smaller {
	font-family: sans-serif;
	font-size: 11px;
	line-height: 1.5em;
	vertical-align: baseline;
	letter-spacing: normal;
	word-spacing: 0px;
	margin-top: -6px;
}


/* --------------- EVENTS PAGES ------------------------------------------------------------------- */
.events_header_roundel_top {
	width: 189px;
	height: 26px;
	float: right;
	margin-top: 20px;
}
.event_flash {
	background: url(/media/images/event-upcoming-flash_2330.gif) right bottom no-repeat;
	min-height: 139px;
}
.event_date {
	position: relative;
	font-family: 'Open Sans', sans-serif;
	font-weight: 300;
	font-size: 24px;
	text-align:left;
	vertical-align: baseline;
	letter-spacing: -0.6px;
	word-spacing: 0px;
	margin-bottom: 24px;
  	line-height: 1.7em;
}

/* --------------- Infinite Scroll ------------------------------------------------------------------- */
.loading {
	background:#c1c39a;
	color:#303030;
	font-size:20px;
	padding:5px 10px;
	text-align:center;
	width:450px;
	margin:0px auto;
	display:none;
	border-radius: 5px;
}

/* --------------- LIGHTBOX (BASIC SETUP)------------------------------------------------------------------- */

#lightbox {
	position: absolute;
	top: auto;
	left: 0px;
	right: 0px;
	margin-top: 100px;
	/*background-color: #8ca7b4;*/
	z-index: 100001;
	display: none;
	
}
#lightbox-shadow {
	position: absolute;
	top: 0;
	left: 0;
	right: 0px;
	bottom: 0px;
	background: #000;
	
	/* IE 8 */
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=75)";
	/* IE 5-7 */
	filter: alpha(opacity=75);
	/* Netscape */
	-moz-opacity: 0.75;
	/* Safari 1.x */
	-khtml-opacity: 0.75;
	/* Good browsers */
	opacity: 0.75;
	
	z-index: 100000;
	display: none;
}
.lightboxclosebtn {
	position: relative;
	background: url(/media/images/lightbox-cross_2329.gif) no-repeat 0 0;
	width: 33px;
	height: 33px;	
	cursor: pointer;
	float: right;
	margin-top: -10px;
	margin-right: -10px;
}
.lightboxclosebtn_bg {
	background: #F5F5F5;
	width: 100%;
	height: 30px;
}
.lightboxclosebtn:hover { background-position: 0 -33px; }

/* --------------- LIGHTBOX (GENERIC) ------------------------------------------------------------------- */

.lightboxcont_gnrc {
	
}
.lightbox_gnrc {
	border: #FFF 8px solid;
	background-color: #273742;
	padding: 60px;
	overflow: hidden;
}
.lightbox_gnrc2 {
	border: #FFF 8px solid;
	background-color: #f5f5f5;
	padding: 0px;
	overflow: hidden;
 	width: 670px;
	margin: 0 auto; /* the auto value on the sides, coupled with the width, centers the layout */
}
/*.lightbox_transp_bg {
	background-color:#0f0;
}*/
.lightbox_reg_form {
	border: #FFF 8px solid;
	background-color: #273742;
	padding: 60px;
	overflow: hidden;
}

/* --------------- LIGHTBOX (NEW MAG COMING SOON) ------------------------------------------------------------------- */

#awardslightboxcont {
	display: none;
}
#awardslightbox {
	width: 860px;
	border: #FFF 8px solid;
	background-color: #273742;
	padding: 60px;
	overflow: hidden;
}
.fb_h1_lightbox {
	font-size: 33px;
	width: 426px;
	padding: 0 0 36px 0;
	/*background-color: #0f0;*/
}
.fb_para_lightbox {
	width: 426px;
	padding: 0;
	/*background-color: #f00;*/
}

/*--------------------------------------------------------------------- STORY COMMENTS SECTION ---------------------------------------------------------------- */
#sign_to_comment{
	margin-top:30px;
	background:#886D97; 
	height:42px;
}
#comments_signin_wording{
	position:relative;
	top:8px;
	left:15px;
	font-size:18px;
	color:#fff; 
}
.account_strip_comments {
	width: 100%;
	height: 20px;
}
.account_btn_main_comments {
	position:relative;
	border-left: 1px solid #ccc;
	color:#fff;
	font-size: 12px;
	top:-25px;
	padding: 13px 14px 0 14px;
	height: 27px;
	float: right;
	text-transform: uppercase;
}
.leave_comment{
	padding-bottom: 10px;
	border-bottom: 1px solid #c1c1c1;
	margin-bottom: -10px;
}
#leave_comment_text{
	background:#886D97;
	padding-left:5px;
	color:#fff;
	font-size:16px;
	margin-bottom:30px;
}
.comments{
	width: 100%;
	border-bottom: 1px solid #c1c1c1;
	margin-bottom: -10px;
}
.avatar_box {
	width: 8.1%;
	background-color: #03C;
}
.avatar {
	width: 54px;
	height: 54px;
	background-color: #ccc;
}
.comment_list_box {
	width: 89.3%;
	
}
.comment_list_username { 
	
}
.comment_list_date {
	font-size:10px;
	
}
.comment_list_para {
	width: 90%;
	margin-left: 63px;
}

.comment_text_area{
	width: 100%;
	min-width: 100%;
	max-width: 100%;
	min-height: 35px;
	height:100px;
	
	background:#fff; 
	border: 1px solid #979696;
	color:#000; 
	font: 13px Arial, Helvetica, sans-serif;
	padding: 9px;
	margin-bottom: 14px;
	
	-moz-box-sizing:    border-box;
    -webkit-box-sizing: border-box;
    box-sizing:        border-box;
}



/* ------------------ GLOBAL MOBILITY PAGES -------------------------------------------------------*/
#gm_image1{
  position:relative;
  left:500px;
  top:-150px;
  height:306px;
	background: url(/media/images/cipd-woman-mob_2328.jpg) no-repeat;		
}
#gm_image2{
  position:relative;
  left:650px;
  top:-130px;
  height:306px;
	background: url(/media/images/cipd-roundel2_2327.jpg) no-repeat;		
}
#gm_image3{
  position:relative;
  left:650px;
  top:-130px;
  height:306px;
	background: url(/media/images/cipd-roundel2_2327.jpg) no-repeat;		
}
#gm_image_careers{
  position:relative;
  left:650px;
  top:-130px;
  height:306px;
	background: url(/media/images/img-main-careers_2326.jpg) no-repeat;		
}
#gm_logo{
  z-index:100;
  position:relative;
  left:300px;
  top:50px;
  height:153px;
	background: url(/media/images/cipd-logo_2325.png) no-repeat;		
}
#gm_logo_careers{
  z-index:100;
  position:relative;
  left:300px;
  top:50px;
  height:153px;
	background: url(/media/images/logo-careers_2324.gif) no-repeat;		
}
#gm_htop_holder{
 height:100px; 
}
.content_gm {
	position: relative;
	width: 670px;
	margin: 14px 0 19px 0;
	padding: 100px 0 0 19px;
	float:left;
	border-left: 1px dotted #e6e6e6;
}
.content_gm h2 {
	font-family: 'Open Sans', sans-serif;
	font-size: 18px;
	font-weight: 600;
	padding: 10px 0;
	line-height: 1.6em;
	width: 550px;
	margin: 0 0 10px 0;
}
.content_gm h3 {
	position: relative;
	display: block;
	float: left;
	font-family: 'Open Sans', sans-serif;
	font-size: 14px;
	font-weight: 600;
	padding: 10px 0;
	line-height: 1.6em;
	width: 550px;
	color: #4A7194;
	-webkit-transition: all .3s ease;
	-moz-transition: all .3s ease;
	-o-transition: all .3s ease;
	-ms-transition: all .3s ease;
	transition: all .3s ease;
}
#gm_h2{
	color:#886D97;
	margin-bottom: 20px;
}

/* ------------------ Big Slider (Carousel) -------------------------------------------------------*/
.big_slider_inner {
	position:relative;
	width: 100%; /* important (this width = width of list item(including margin) * items shown */ 
	overflow: hidden;  /* important (hide the items outside the div) */
	/* non-important styling bellow */
	margin-bottom: 20px;
}
.big_slider_controls_left {
	float: left;
	position:relative;
  	margin: -194px 0 0 0;
}
.big_slider_controls_right {
	float: right;
	position:relative;
  	margin: -194px 0 0 0;
}
.big_slider_ul {
	position:relative;
	list-style-type: none; /* removing the default styling for unordered list items */
	margin: 0px;
	padding: 0px;
	padding-bottom:10px;
	
	.hdlne_big{
		min-height: 84px;
	}
}
.big_slider_ul li {
	float: left; /* important for inline positioning of the list items */                                    
	/* just styling below*/
	padding: 0px;
	margin: 0; 
}

.big_slider_ul li a {
	display: block;
    position: relative;
    width: 100%;	
}
.big_slider_ul li img {
	margin-bottom:-4px; /* IE is making a 4px gap bellow an image inside of an anchor (<a href...>) so this is to fix that*/
	/* styling */
	border:0px; 
}

/* ------------------ Media Carousel -------------------------------------------------------*/
.carousel_inner {
	position:relative;
	width: 100%; /* important (this width = width of list item(including margin) * items shown */ 
	overflow: hidden;  /* important (hide the items outside the div) */
	/* non-important styling bellow */
}
.carousel_controls_left {
	float: left;
	position:relative;
  	margin: -134px 0 0 -17px;
}
.carousel_controls_right {
	float: right;
	position:relative;
  	margin: -134px -18px 0 0;
}

/* .carousel_ul {
	position:relative;
	left:-227px; important (this should be negative number of list items width(including margin)
	list-style-type: none; removing the default styling for unordered list items
	margin: 0px;
	padding: 0px;
	width:9999px; important
	non-important styling bellow
	padding-bottom:10px;
}
.carousel_ul li {
	float: left; important for inline positioning of the list items                                    
	width: 200px;  fixed width, important
	just styling bellow
	padding: 0px;
	margin: 0 9px; 
}
.carousel_ul li img {
	margin-bottom:-4px; IE is making a 4px gap bellow an image inside of an anchor (<a href...>) so this is to fix that
	styling
	border:0px; 
} */

.content .carousel_ul{
	padding:0;
}

.carousel_ul_no_move {
	position:relative;
	left:-9px; /* important (this should be negative number of list items width(including margin) */
	list-style-type: none; /* removing the default styling for unordered list items */
	margin: 0px;
	padding: 0px;
	width:9999px; /* important */
	/* non-important styling bellow */
	padding-bottom:10px;
}
.carousel_ul_no_move li {
	float: left; /* important for inline positioning of the list items */                                    
	width: 200px;  /* fixed width, important */
	/* just styling bellow*/
	padding: 0px;
	margin: 0 9px; 
}
.carousel_ul_no_move li img {
	margin-bottom:-4px; /* IE is making a 4px gap bellow an image inside of an anchor (<a href...>) so this is to fix that*/
	/* styling */
	border:0px; 
}
.left_scroll, .qklink_left_scroll, .big_slider_left_scroll {
	height:36px; 
	width:24px; 
	background-color: #C0C0C0;
	/* IE 8 */
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=90)";
	/* IE 5-7 */
	filter: alpha(opacity=90);
	/* Netscape */
	-moz-opacity: 0.9;
	/* Safari 1.x */
	-khtml-opacity: 0.9;
	/* Good browsers */
	opacity: 0.9;
}
.right_scroll, .qklink_right_scroll, .big_slider_left_scroll {
	height:36px; 
	width:24px; 
	background-color: #C0C0C0; 
	/* IE 8 */
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=90)";
	/* IE 5-7 */
	filter: alpha(opacity=90);
	/* Netscape */
	-moz-opacity: 0.9;
	/* Safari 1.x */
	-khtml-opacity: 0.9;
	/* Good browsers */
	opacity: 0.9;
}
.left_scroll img, .right_scroll img, .qklink_left_scroll img, .qklink_right_scroll img, .big_slider_left_scroll img, .big_slider_right_scroll img {
	/*styling*/
	cursor: pointer;
	cursor: hand;
}
.media_main_bg {
	background-color: #3e3e3e;
	max-height: 0;
	overflow: hidden;
	@include transition(max-height 0.5s ease-out);
	
	&.active{
		max-height: 800px;
	}
}

.media_main_box {
	border: 10px solid #fff;
	display: table;
	margin: 30px auto 38px auto;
}
.media_close_btn {
	background: url(/media/images/arrow-up-light_2323.gif) center center no-repeat #886d97;
	cursor: pointer;
	height: 20px;
}
.hi-icon-wrap {
	text-align: center;
	margin: 0 auto;
}
.hi-icon {
	display: inline-block;
	font-size: 0px;
	cursor: pointer;
	margin: -75px 0 0 -20px;
	width: 40px;
	height: 40px;
	border-radius: 50%;
	text-align: center;
	position: absolute;
	z-index: 1;
	color: #F00;
	box-shadow: 0 0 0 2px #fff;
	background: url(/media/images/media-play-icon_2322.png) no-repeat 0 0;
}
.hi-icon:after {
	pointer-events: none;
	position: absolute;
	width: 100%;
	height: 100%;
	border-radius: 50%;
	content: '';
	-webkit-box-sizing: content-box; 
	-moz-box-sizing: content-box; 
	box-sizing: content-box;
}
/* Effect 1 */
.hi-icon-effect-1 .hi-icon {
	background: url(/media/images/media-play-icon_2322.png) no-repeat 0 0 rgba(136,109,151,0.8);
	-webkit-transition: background 0.2s, color 0.2s;
	-moz-transition: background 0.2s, color 0.2s;
	transition: background 0.2s, color 0.2s;
}
.hi-icon-effect-1 .hi-icon:after {
	top: -8px;
	left: -8px;
	padding: 8px;
	box-shadow: 0 0 0 2px #fff;
	-webkit-transition: -webkit-transform 0.2s, opacity 0.2s;
	-webkit-transform: scale(.8);
	-moz-transition: -moz-transform 0.2s, opacity 0.2s;
	-moz-transform: scale(.8);
	-ms-transform: scale(.8);
	transition: transform 0.2s, opacity 0.2s;
	transform: scale(.8);
	opacity: 0;
}
/* Effect 1a */
.expander_btn:hover .hi-icon {
	background: rgba(136,109,151,1);
	color: #41ab6b;
}
.expander_btn:hover .hi-icon:after  {
	-webkit-transform: scale(1);
	-moz-transform: scale(1);
	-ms-transform: scale(1);
	transform: scale(1);
	opacity: 1;
}

/* ------------------ Quick Links Carousel -------------------------------------------------------*/

.qklink_big_box {
	padding: 1.8%;
	height: auto;
	margin-top: -15px;
	margin-bottom:10px;
}
.qklink_inner {
	position:relative;
	width: 100%; /* important (this width = width of list item(including margin) * items shown */ 
	overflow: hidden;  /* important (hide the items outside the div) */
}
.qklink_controls_left_geo, .qklink_controls_left_sect {
	float: left;
	position:relative;
  	margin: -89px 0 0 -12px;
}
.qklink_controls_right_geo, .qklink_controls_right_sect {
	float: right;
	position:relative;
  	margin: -89px -12px 0 0;
}
.qklink_ul_geo, .qklink_ul_sect {
	position:relative;
	left:-129px; /* important (this should be negative number of list items width(including margin) */
	list-style-type: none; /* removing the default styling for unordered list items */
	margin: 0px;
	padding: 0px;
	width:9999px; /* important */
	/* non-important styling bellow */
	padding-bottom:10px;
}
.qklink_ul_geo li, .qklink_ul_sect li {
	position:relative;
	float: left; /* important for inline positioning of the list items */                                    
	width: 128px;  /* fixed width, important */
	/* just styling bellow*/
	padding: 0px;
	margin: 0; 
	border-left: 1px solid #c5c5c5;
	list-style: none;
	position: relative;
	display: inline-block;
}
.qklink_ul_geo li img, .qklink_ul_sect li img {
	margin-bottom:-4px; /* IE is making a 4px gap bellow an image inside of an anchor (<a href...>) so this is to fix that*/
	/* styling */
	border:0px; 
}

.qklink_all_box {
	position: relative;
	display: block;
	width: 128px;
	height: 135px;
	overflow: hidden;
}
.qklink_underlay_box {
	position: absolute;
	width: 128px;
	height: 135px;
}
.qklink_overlay_box {
	position: absolute;
	background-color: #464646;
	width: 118px;
 	padding: 0 5px;
	height: 135px;
	top: 135px;
}
.qklink_carousel_img {
	/* styling */
	border:0px;
}
.qklink_title {
	margin: 77px 8px 0 8px;
	font-family: 'Open Sans', sans-serif;
	font-size:13px;
	font-weight: 600!important;
	line-height: 1.2em;
	text-align:center;
	padding: 7px 0 7px 0;
	vertical-align: baseline;
	letter-spacing: -0.6px;
	word-spacing: 0px;
	text-transform: uppercase;
}
.qklink_click_here {
	margin-top: 66px;
	font-family: 'Open Sans', sans-serif;
	font-size:11.5px;
	font-weight: 300!important;
	line-height: 1.4em;
	text-align:center;
	padding: 7px 0 7px 0;
	vertical-align: baseline;
	word-spacing: 0px;
	color: #fff;
  padding: 8px;
}
.round {
	display: block;
	position: absolute;
	width: 150px;
	height: 150px;
	padding-top: 30px;		
	text-decoration: none;		
	text-align: center;
	font-size: 25px;		
	text-shadow: 0 1px 0 rgba(255,255,255,.7);
	letter-spacing: -.065em;
	font-family: "Hammersmith One", sans-serif;		
	-webkit-transition: all .25s ease-in-out;
	-o-transition: all .25s ease-in-out;
	-moz-transition: all .25s ease-in-out;
	transition: all .25s ease-in-out;
	box-shadow: 2px 2px 7px rgba(0,0,0,.2);
	border-radius: 50%;
	z-index: 1;
	border-width: 4px;
	border-style: solid;
}
div.red {
	color: rgba(239,57,50,1);
}

.qklink-icon-wrap {
	text-align: center;
	margin: 0 auto;
}
.qklink-icon {
	display: inline-block;
	font-size: 0px;
	cursor: pointer;
	margin: 4px 0 0 -35px;
	padding: 17px;
	width: 36px;
	height: 36px;
	border-radius: 50%;
	text-align: center;
	position: absolute;
	z-index: 1;
	color: #F00;
}
/* Effect 1 */
.qklink-icon-effect-1 .qklink-icon {

  background-color: rgba(136,109,151,1);
	-webkit-transition: background 0.2s, color 0.9s;
	-moz-transition: background 0.2s, color 0.9s;
	transition: background 0.2s, color 0.9s;
  -webkit-transition: -webkit-transform 0.2s,;
	-webkit-transform: scale(1);
	-moz-transition: -moz-transform 0.2s,;
	-moz-transform: scale(1);
	-ms-transform: scale(1);
	transition: transform 0.2s,;
	transform: scale(1);
}
/* Effect 1a */
.qklink_all_box:hover .qklink-icon {
	/*background: rgba(136,109,151,1);*/
	color: #41ab6b;
  -webkit-transform: scale(0.75);
	-moz-transform: scale(0.75);
	-ms-transform: scale(0.75);
	transform: scale(0.75);
	opacity: 1;
}
.qklink_colour_darkblue .qklink-icon { background-color: rgba(59,80,111,1); }
.qklink_colour_red .qklink-icon { background-color: rgba(203,46,43,1); }
.qklink_colour_mustard .qklink-icon { background-color: rgba(179,162,97,1); }
.qklink_colour_green .qklink-icon { background-color: rgba(74,101,25,1); }
.qklink_colour_pink .qklink-icon { background-color: rgba(193,16,120,1); }
.qklink_colour_yellow .qklink-icon { background-color: rgba(218,157,65,1); }
.qklink_colour_blue .qklink-icon { background-color: rgba(12,82,138,1); }
.qklink_colour_purple .qklink-icon { background-color: rgba(128,88,150,1); }
.qklink_colour_turquoise .qklink-icon { background-color: rgba(72,186,191,1); }


/* ------------------ Register Form Carousel -------------------------------------------------------*/
.form_register .slider_inner {
	position:relative;
	width: 100%; /* important (this width = width of list item(including margin) * items shown */ 
	overflow: hidden;  /* important (hide the items outside the div) */
	/* non-important styling bellow */
}
.form_register .carousel_controls_left {
	float: left;
	position:relative;
  	margin: 74px 0 0 0;
}
.form_register .carousel_controls_right {
	float: right;
	position:relative;
  	margin: 74px 0 0 0;
}
.form_register .carousel_ul, .form_register .carousel_strip_cont {
	position:relative;
	left:0px; /* important (this should be negative number of list items width(including margin) */
	list-style-type: none; /* removing the default styling for unordered list items */
	margin: 0px;
	padding: 0px;
	width:400%; /* important */
	/* non-important styling bellow */
	padding-bottom:10px;
}
.form_register .carousel_strip_col {
	width:25%; /* important */
	float: left;
}
.form_register .carousel_ul li {
	float: left; /* important for inline positioning of the list items */                                    
	width: 200px;  /* fixed width, important */
	/* just styling bellow*/
	padding: 0px;
	height: 108px;
	background: #000000;
	margin: 0 9px; 
}
.form_register .carousel_ul li img {
	margin-bottom:-4px; /* IE is making a 4px gap bellow an image inside of an anchor (<a href...>) so this is to fix that*/
	/* styling */
	border:0px; 
}
.form_register .slide_left {
	height:36px; 
	width:24px; 
	background-color: #C0C0C0;
	/* IE 8 */
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=90)";
	/* IE 5-7 */
	filter: alpha(opacity=90);
	/* Netscape */
	-moz-opacity: 0.9;
	/* Safari 1.x */
	-khtml-opacity: 0.9;
	/* Good browsers */
	opacity: 0.9;
}
.form_register .slide_right {
	height:36px; 
	width:24px; 
	background-color: #C0C0C0; 
	/* IE 8 */
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=90)";
	/* IE 5-7 */
	filter: alpha(opacity=90);
	/* Netscape */
	-moz-opacity: 0.9;
	/* Safari 1.x */
	-khtml-opacity: 0.9;
	/* Good browsers */
	opacity: 0.9;
}
.form_register .slide_left img, .form_register .slide_right img {
	/*styling*/
	cursor: pointer;
	cursor: hand;
}
.form_register .media_main_bg {
	background-color: #3e3e3e; 
	border-top: 2px solid #000;
}

.form_register .media_main_box {
	border: 10px solid #fff;
	display: table;
	margin: 30px auto 38px auto;
}
.form_register .media_close_btn {
	background: url(/media/images/arrow-up-light_2323.gif) center center no-repeat #886d97;
	cursor: pointer;
	height: 20px;
}
.square_arrow_btn_right {
	background: url(/media/images/arrow-double-1pix-right_2321.gif) center center no-repeat #886d97;
	cursor: pointer;
	width: 40px;
	height: 40px;
	
	transition: all .25s ease-in-out;
	-moz-transition: all .25s ease-in-out;
	-webkit-transition: all .25s ease-in-out;
}
.square_arrow_btn_right:hover { 
	background-color: #9374a4;
	background-position: 14px center;
}
.square_arrow_btn_left {
	background: url(/media/images/arrow-double-1pix-left_2320.gif) center center no-repeat #886d97;
	cursor: pointer;
	width: 40px;
	height: 40px;
	
	transition: all .25s ease-in-out;
	-moz-transition: all .25s ease-in-out;
	-webkit-transition: all .25s ease-in-out;
}
.square_arrow_btn_left:hover { 
	background-color: #9374a4;
	background-position: 9px center;
}
.circle_page_grey {
	width:32px;
	height:32px;
	border-radius:16px;
	font-family: 'Open Sans', sans-serif;
	font-weight: 300;
	font-size:20px;
	color:#fff;
	line-height:32px;
	text-align:center;
	background-color:#bfbfbf;
	margin: 5px 0 0 12px;
}
.circle_page_live { background-color:#886d97; }

.circle_page_ticked { 
	background: url(/media/images/tick-pg-num_2319.png) 0 0 no-repeat #a7ce5c;
}



/* ------------------ Ads Slider -------------------------------------------------------*/
div#slide-holder {
	width : 250px;
	height : 446px;
	position : relative;
	margin-bottom: 30px;
}
div#slide-holder div#slide-runner {
	width : 250px;
	height : 400px;
	overflow : hidden;
	position : relative;
}
.ad_slider_a { border: none!important; }

#slide-runner .slide {
	margin : 0;
	display : none;
	position : absolute;
}
.initial_image {
	position : absolute;
	top: 0px;
	left: 0px;
}
.extra_images {
	position : absolute;
	top: 0px;
	left: 0px;
}
.fade_slider_div {
	position: absolute;
	z-index: 1;
}
.fade_slider_div_vis {
	z-index: 2;
}
div#slide-holder div#slide-controls {
	left : 0;
	width : 250px;
	height : 46px;
	display : none;
	position : relative;
	background-color: #535353;
}

div#slide-holder div#slide-controls p#slide-nav {
	float : left;
	height : 24px;
	display : inline;
	margin : 13px 15px 0 11px;
}
div#slide-holder div#slide-controls p#slide-nav a {
	float : left;
	width : 24px;
	height : 24px;
	display : inline;
	font-size : 11px;
	margin : 0 0 0 2px;
	line-height : 24px;
	text-align : center;
	text-decoration : none;
	background-position : 0 0;
	background-repeat : no-repeat;
	color : #fff!important;
	text-decoration : none!important;
	font-size : 12px;
	text-align : center;
	font-family: 'Open Sans', sans-serif;
	font-weight : 700;
}
div#slide-holder div#slide-controls p#slide-nav a.on {
	/*background-position : 0 -24px;*/
}
div#slide-holder div#slide-controls p#slide-nav a {
	/*background-image : url(images/ad_slider_radio.gif);*/
}
.ad_slider_radio {
	width:17px;
	height:17px;
	background-image : url(/media/images/ad-slider-radio_2318.gif);
}
.ad_slider_radio_sel {
	width:17px;
	height:17px;
	background-image : url(/media/images/ad-slider-radio-sel_2317.gif);
	position : absolute;
	top: 13px;
}


/* ------------------ Admin Bar -------------------------------------------------------*/
.adminbar{
background-color:#000;
color:#fff;
position:relative;
overflow:auto;
z-index:1000;
}

#admin_bar_name{
float:left;
padding-left:20px;
}
#menu{
float:right;
padding-right:20px;
}

/* ------------------ START OF RESPONSIVE BUILD (beginning with Serviced Accom section)-------------------------------------------------------*/
/*  SECTIONS  */
.section {
	clear: both;
	padding: 0px;
	margin: 0px;
}

/*  COLUMN SETUP  */
.col {
	display: block;
	float:left;
	margin: 0 0 0 2.6%;
}
.col_ftr {
	display: block;
	float:left;
	margin: 1% 0 1% 0%;
}

.col:first-child { margin-left: 0; }

.col_right {
	display: block;
	float:right;
	margin: 0 0 0 2.6%;
}


/*  GROUPING  */
.group:before,
.group:after {
	content:"";
	display:table;
}
.group:after {
	clear:both;
}
/*  GRID OF NO WIDTH  */
.span_no_width { 
	display: inline-block;
}    
/*  GRID OF FULL WIDTH  */
.span_full_width { 
	width: 100%;
}                                            
/*  GRID OF FREEFORM  */
.span_freeform { 
	width: 48.7%;
}
			
/*  GRID OF TWO  */
.span_2_of_2 {
	width: 100%;
}
.span_1_of_2 {
	width: 48.7%;
}
.span_1_of_2_stick {
	width: 48.7%;
}
/*  GRID OF TWO (extra width for left col)  */
.span_1_extra_of_2 {
	width: 62.4%;
}
.span_1_less_of_2 {
	width: 35%;
}
/*  GRID OF TWO (HOME PAGE SLIDER PLUS FEATURE COLUMN)  */
.span_1_of_2_topmod_lft {
	width: 72%;
}
.span_1_of_2_topmod_rgt {
	width: 22.6%;
	padding: 0 0 0 2.6%;
	border-left: 1px dotted #ccc;
}
/*  GRID OF TWO (HOME PAGE MAIN NEWS & ARTICLES)  */
.span_1_of_2_topmod_news_lft {
	width: 46.1%;
	padding: 0 3.7% 0 0;
}
.span_1_of_2_topmod_news_rgt {
	width: 46.1%;
	padding: 0 0 0 3.7%;
	border-left: 1px dotted #ccc;
}
/*  GRID OF THREE  */
.span_3_of_3 {
	width: 100%;
}
.span_2_of_3 {
	width: 65.8%;
}
.span_1_of_3 {
	width: 31.6%;
}
/*  GRID OF THREE (FOOTER) */
.span_3_of_3_ftr {
	width: 100%;
}
.span_2_of_3_ftr {
	width: 66.66%;
}
.span_1_of_3_ftr {
	width: 33.33%;
}
.span_1_of_3_ftr_nrw {
	width: 22%;
}
.span_1_of_3_ftr_wde {
	width: 39%;
}
/*  GRID OF FOUR  */
.span_4_of_4 {
	width: 100%;
}
.span_3_of_4 {
	width: 74.35%;
}
.span_2_of_4 {
	width: 48.7%;
}
.span_1_of_4 {
	width: 23.05%;
}

.span_1_of_4:nth-child(4n+1){
	margin-left: 0;
	clear: left;
}

/*  GRID OF FIVE  */
.span_5_of_5 {
	width: 100%;
}
.span_4_of_5 {
	width: 80%;
}
.span_3_of_5 {
	width: 60%;
}
.span_2_of_5 {
	width: 40%;
}
.span_1_of_5 {
	width: 20%;
}
/*  GRID OF SIX  */
.span_6_of_6 {
	width: 100%;
}

.span_5_of_6 {
  	width: 83.33%;
}

.span_4_of_6 {
  	width: 66.66%;
}

.span_3_of_6 {
  	width: 50%;
}

.span_2_of_6 {
  	width: 33.33%;
}

.span_1_of_6 {
  	width: 16.66%;
}


/*  GO FULL WIDTH AT LESS THAN 480 PIXELS */

@media only screen and (max-width: 480px) {
	.col { 
		margin: 1% 0 1% 0%;
	}
	.col_ftr { 
		margin: 1% 0 1% 0%;
	}
	.wrap_content_resp_test {
		width: 100%;
	}
	.content {
		width: 100%;
	}
}

@media only screen and (max-width: 480px) {
	.col img  {
		max-width: 100%; 
		height: auto;
		}
		
	.span_no_width { 
		width: 100%;
	}     
	/*  GRID OF FREEFORM  */
	.span_freeform { 
		width: 100%;
	}
	/*  GRID OF TWO  */
	.span_2_of_2 {
		width: 100%;
	}
	.span_1_of_2 {
		width: 100%;
	}
	/*  GRID OF TWO (extra width for left col)  */
	.span_1_extra_of_2 {
		width: 100%;
	}
	.span_1_less_of_2 {
		width: 100%;
	}
	/*  GRID OF TWO (TOP MODULE ON HOME PAGE)  */
	.span_1_of_2_topmod_lft {
		width: 70.3%;
		background: #0f0;
	}
	.span_1_of_2_topmod_rgt {
		width: 24.5%;
		padding: 0 0 0 1.6%;
		background: #0f0;
	}
	/*  GRID OF THREE  */
	.span_3_of_3 {
		width: 100%; 
	}
	.span_2_of_3 {
		width: 100%; 
	}
	.span_1_of_3 {
		width: 100%;
	}
	/*  GRID OF THREE (FOOTER) */
	.span_3_of_3_ftr {
		width: 100%; 
	}
	.span_2_of_3_ftr {
		width: 100%; 
	}
	.span_1_of_3_ftr {
		width: 100%;
	}
	.span_1_of_3_ftr_nrw {
		width: 100%;
	}
	.span_1_of_3_ftr_wde {
		width: 100%;
	}
	/*  GRID OF FOUR  */
	.span_4_of_4 {
		width: 100%; 
	}
	.span_3_of_4 {
		width: 100%; 
	}
	.span_2_of_4 {
		width: 100%; 
	}
	.span_1_of_4 {
		width: 100%;
	}
	.dir_feat_text {/*NEW BUILD*/
		width: 100%;
		height: auto;
	}
	.dir_resp_test {
		width: 100%;
	}
	.soc_media_float {
		display: block;
		float: left!important;
	}
	/*  GRID OF FIVE  */
	.span_5_of_5 {
		width: 100%; 
	}
	.span_4_of_5 {
		width: 100%; 
	}
	.span_3_of_5 {
		width: 100%; 
	}
	.span_2_of_5 {
		width: 100%; 
	}
	.span_1_of_5 {
		width: 100%;
	}
	/*  GRID OF SIX  */
	.span_6_of_6 {
		width: 100%; 
	}
	.span_5_of_6 {
		width: 100%; 
	}
	.span_4_of_6 {
		width: 100%; 
	}
	.span_3_of_6 {
		width: 100%; 
	}
	.span_2_of_6 {
		width: 100%; 
	}
	.span_1_of_6 {
		width: 100%;
	}
}

/* --------------------------------------------------------------------------------------------------------------------------------------------------------------*/
.big_grey_box {
	background-color: #F5F5F5;
	padding: 2.6%;
	height: auto;
}
.big_grey_box_half_pad {
	background-color: #F5F5F5;
	padding: 2% 2% 2% 2.1%;
	height: auto;
}
.big_grey_box_darker { /* this selector removes the default blue border displayed in some browsers around an image when it is surrounded by a link */
	background-color: #F5F5F5;
	padding: 2.6%;
	transition: all .3s ease-in-out;
	-moz-transition: all .3s ease-in-out;
	-webkit-transition: all .3s ease-in-out;
}
.big_grey_box_inset_strip {
	background-color: #dedede;
	padding: 2.6%;
	border-top: 1px solid #c1c1c1;
}
.fade_to_vis { background-color: #dcdcdc; }
	
.btn_teal {
	font-size: 11px;
	color: #fff;
	text-transform: uppercase;
	font-family: 'Open Sans', sans-serif!important;
	font-weight: 600;
	padding: 5px 12px 5px 12px;
	background-color: #567a8d;
	display: inline-block;
	vertical-align: top;
	cursor: pointer;
	
	border-radius: 2px;
	-webkit-border-radius: 2px;
	-moz-border-radius: 2px;
	-ms-border-radius: 2px;
	
	transition: all .3s ease-in-out;
	-moz-transition: all .3s ease-in-out;
	-webkit-transition: all .3s ease-in-out;
}
.btn_teal:hover, .btn_teal:active, .btn_teal:focus {
	color: #fff;
	background-color: #5e859a;
}
.hdlne_big_window {
	display: inline-block;
	position: relative;
	width: 100%;
	overflow: hidden;
	user-select: none;
}
.hdlne_big_box {
	display: inline-block;
	position: absolute;
	width: 100%;
}
.hdlne_big {
	font-family: 'Open Sans', sans-serif;
	font-size:24px;
	font-weight: 600;
	line-height: 1.1em;
	text-align:left;
	padding: 15px 20px;
	vertical-align: baseline;
	letter-spacing: -0.6px;
	word-spacing: 0px;
	text-transform: uppercase;
	color: #fff;
	background-color: #5c5c5c;
	padding: 15px 35px;
	
	/* IE 8 */
	/*-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=95)";*/
	/* IE 5-7 */
	/*filter: alpha(opacity=75);*/
	/* Netscape */
	/*-moz-opacity: 0.75;*/
	/* Safari 1.x */
	/*-khtml-opacity: 0.75;*/
	/* Good browsers */
	/*opacity: 0.75;*/
}
.hdlne_big_catch_text {
	font-family: sans-serif;
	font-size: 12px;
	color: #fff;
	line-height: 1.5em (18px);
	vertical-align: baseline;
	letter-spacing: normal;
	word-spacing: 0px;
	display: block;
	background-color: #5c5c5c;
	padding: 0 35px 17px;
	
	/* IE 8 */
	/*-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=95)";*/
	/* IE 5-7 */
	/*filter: alpha(opacity=75);*/
	/* Netscape */
	/*-moz-opacity: 0.75;*/
	/* Safari 1.x */
	/*-khtml-opacity: 0.75;*/
	/* Good browsers */
	/*opacity: 0.75;*/
}
.hdlne_med {
	font-family: 'Open Sans', sans-serif;
	font-size:13px;
	font-weight: 600!important;
	line-height: 1.5em (19.5px);
	text-align:left;
	padding: 13px 0 13px 0;
	vertical-align: baseline;
	letter-spacing: -0.6px;
	word-spacing: 0px;
	display: inline-block;
}
.hdlne_med_ruled {
	font-family: 'Open Sans', sans-serif;
	font-size:13px;
	font-weight: 600!important;
	line-height: 1.5em (19.5px);
	text-align:left;
	padding: 10px 0 13px 0;
	vertical-align: baseline;
	letter-spacing: -0.6px;
	word-spacing: 0px;
	border-bottom: solid 1px #f0f0f0;
	margin: 0 0 18px 0;
}
.hdlne_med_qklink {
	font-family: 'Open Sans', sans-serif;
	font-weight: 600!important;
	font-size:13px;
	color: #fff;
	line-height: 1.5em (19.5px);
	text-align:left;
	padding: 13px 0 0 0;
	vertical-align: baseline;
	/*letter-spacing: -0.6px;*/
	word-spacing: 0px;
}
.para_med_ruled {
	text-indent: 1px;
	line-height: 1.5em (19.5px);
	border-top: solid 1px #ccc;
	padding: 6px 0 6px 0;
	margin-bottom: 0;
}
.para_med_ruled a {
	color: #242424;
	font-weight: bold;
}
.para_med_ruled a:hover {
	color: #4A7194;
	text-decoration: none;
}
.btn_teal_cont {
	background:url(/media/images/pattern-micro-grid_2395.gif) 0 0 #e1e1e1;
	margin-bottom: 12px;
}
.btn_teal {
	font-size: 11px;
	color: #fff;
	text-transform: uppercase;
	font-family: 'Open Sans', sans-serif!important;
	font-weight: 600;
	padding: 5px 12px 5px 12px;
	background-color: #567a8d;
	display: inline-block;
	vertical-align: top;
	
	transition: all .3s ease-in-out;
	-moz-transition: all .3s ease-in-out;
	-webkit-transition: all .3s ease-in-out;
}
.btn_teal:hover, .btn_teal:active, .btn_teal:focus {
	color: #fff;
	background-color: #5e859a;
}
.intro_box {
	margin-bottom: 12px;
}
.intro_para {
	margin: 8px 0 0 6px;
}
.module_articles {
	margin-bottom: 14px;
 	padding-top: 14px;
 	clear: both;
}
/* --------------- Blocks that jQuery animate to reveal more info ------------------------------------------------------------------- */
.extrainfoblockwindow {
	position: relative;
	width: 100%;
	height: 150px;
	margin-bottom: 18px;
	overflow: hidden;
	user-select: none;
}
.extrainfoblockcontentbot {
	position: absolute;
	top: 212px;
	width: 100%;
	height: 150px;
	background-color: #F00;
	font-family: sans-serif;
	font-size: 12px;
	line-height: 1.6em;
	color: #FFF;
}
#extrainfoblockcontentbot1 { background-color: #5994B3; }
#extrainfoblockcontentbot2 { background-color: #73724B; }
#extrainfoblockcontentbot3 { background-color: #DF4B75; }
#extrainfoblockcontentbot4 { background-color: #A15968; }
#extrainfoblockcontentbot5 { background-color: #C5477B; }
#extrainfoblockcontentbot6 { background-color: #646b74; }
#extrainfoblockcontentbot7 { background-color: #505a4a; }
#extrainfoblockcontentbot8 { background-color: #5b7e7f; }
#extrainfoblockcontentbot9 { background-color: #AFAF4A; }
#extrainfoblockcontentbot10 { background-color: #56537B; }
#extrainfoblockcontentbot11 { background-color: #7D7364; }
#extrainfoblockcontentbot12 { background-color: #5994B3; }

.extrainfoblockcontenttop {
	position: absolute;
	width: 100%;
	height: 150px;
	background-color: #fff;
  	-webkit-box-sizing:border-box;
	-moz-box-sizing:border-box;
	-ms-box-sizing:border-box;
	box-sizing:border-box;
  	border: 9px solid #868686;
	font-family: sans-serif; 
	font-size: 16px;
	line-height: 1.6em;
	color: #fff;
 }
 .textatbottom {
	position: absolute;
	bottom: 12px;
	left: 15px;
	width: 80%;
}
#extrainfoblockcontenttop1 {  }
#extrainfoblockcontenttop2 {  }
#extrainfoblockcontenttop3 {  }
#extrainfoblockcontenttop4 {   }
#extrainfoblockcontenttop5 {  }
#extrainfoblockcontenttop6 {  }
#extrainfoblockcontenttop7 {  }
#extrainfoblockcontenttop8 {  }
#extrainfoblockcontenttop9 {  }
#extrainfoblockcontenttop10 {  }
#extrainfoblockcontenttop11 {  }
#extrainfoblockcontenttop12 {  }

/* ------------------ Marymount Module (Education & Schools) -------------------------------------------------------*/

.bordered_box {
	-webkit-box-sizing:border-box;
	-moz-box-sizing:border-box;
	-ms-box-sizing:border-box;
	box-sizing:border-box;
  	border-left: 9px solid #294886;
  	border-right: 9px solid #294886;
	border-bottom: 9px solid #294886;
	
	background-color: #FFF;
	padding: 2.6%;
	height: auto;
}
.marymount_colour {
	background-color: #375894;
	transition: all .3s ease-in-out;
	-moz-transition: all .3s ease-in-out;
	-webkit-transition: all .3s ease-in-out;
}
.marymount_colour:hover, .marymount_colour:active, .marymount_colour:focus {
	color: #fff;
	background-color: #44639b;
}

/* ------------------ Pullquote Module -------------------------------------------------------*/
.pullqte_box {
	border-left: 1px solid #c1c1c1;
  	padding-left: 8%;
	width: 92%;
	height: 100%;
}
.pullqte_box_quote {
	background-color: #F5F5F5;
	padding: 16px;
	height: auto;
	font-family: Georgia, "times new roman", times,serif;
	font-size: 16px;
	line-height: 1.4em;
	vertical-align: baseline;
	letter-spacing: normal;
	word-spacing: 0px;font-style: italic;
	font-style: italic;
}


.rule_grey_light {
	height: 1px;
	background-color: #f0f0f0;
}
.ruled_box_light {
	border-top: 1px solid #f0f0f0;
	border-bottom: 1px solid #f0f0f0;
	padding: 10px 0;
	clear: both;
}
.video_text_pos {
	margin: 0 0 0 14px;
	line-height: 55px;
	float: left;
}

/* ------------------ Magazine Module -------------------------------------------------------*/
.mod_col1_dotted_rgt {
	width: 90%;
	padding: 0px 17px 0 0;
	border-right: 1px dotted #ccc;
}
.mag_mini_ttl {
	font-family: 'Open Sans', sans-serif;
	font-size:13px;
	font-weight: 600!important;
	line-height: 1.5em (19.5px);
	width: 100%;
	text-align:center;
	padding: 13px 0 13px 0;
	vertical-align: baseline;
	letter-spacing: -0.6px;
	word-spacing: 0px;
	display: inline-block;
	margin-bottom: 0px;
}
.mag_big_ttl {
	font-family: 'Open Sans', sans-serif;
	font-size:14px;
	font-weight: 600!important;
	line-height: 1.5em (19.5px);
	text-align:center;
	padding: 13px 0 13px 0;
	vertical-align: baseline;
	letter-spacing: -0.6px;
	word-spacing: 0px;
	display: inline-block;
	margin-bottom: 0px;
}
.btn_medium {
	padding: 7px 22px;
	border: none;
}


/* ------------------ Generic 1-line classes (ALWAYS MOVE THESE TO THE VERY BOTTOM OF THIS CSS DOCUMENT!! -------------------------------------------------------*/
.float_left_generic { float: left; }
.float_right_generic { float: right; }
.inline_block_generic { display: inline-block; }
.no_margin { margin: 0!important; }
.margin_top_8 { margin-top: 8px!important; }
.margin_top_10 { margin-top: 10px!important; }
.margin_top_20 { margin-top: 20px!important; }
.margin_top_30 { margin-top: 30px!important; }
.margin_bot_8 { margin-bottom: 8px!important; }
.margin_bot_15 { margin-bottom: 15px!important; }
.margin_bot_20 { margin-bottom: 20px!important; }
.margin_bot_30 { margin-bottom: 30px!important; }
.margin_right_20 { margin-right: 20px!important; }
.pad_bot_20 { padding-bottom: 20px!important; }
.no_border { border: none!important; }
.no_pad { padding: 0!important; }
.no_pad_top { padding-top: 0!important; }
.no_pad_bot { padding-bottom: 0!important; }
.auto_width { width: auto!important; }
.full_width { width: 100%!important; }
.half_width { width: 50%!important; }
.width_75 { width: 75%!important; }
.uppercase { text-transform:uppercase; }
.para_no_margin { margin-bottom: 0px; }
.para_extra_margin { margin: 30px 0 30px 0; }
.reversed_text { color: #fff; }
.grey_background { background-color: #F5F5F5; }
.text_right { text-align: right; }
.pos_abs { position: absolute; }
.pos_rel { position: relative; }
.centre_content { margin: 0 auto; }
.bold { font-weight: 900!important; }
.block { display: block; }
.story {padding-top: 14px;padding-bottom: 20px;}


.moreStory {
	padding-top: 14px;
    font-family: sans-serif;
    font-size: 14px;
    line-height: 1.8em;
}

.country-select{
	float: right;
	margin-top: 5px;
	label{
		display: inline-block;
		vertical-align: middle;
		margin-right: 4px;;
	}
	select{
		margin-right: 10px;
	}
	
}
.js .country {
	display: none;
}

.l__moreLink{
	float: right;
	border: none;
	margin-top: 0;
	margin-bottom: 10px;
	margin-top: 15px;
	
	.big_grey_box &{
		margin-top: 10px;
	}
}

.event h2,
.event-landing h2{
	font-family: 'Open Sans', sans-serif;
	font-weight: 300;
	font-size: 24px;
	text-align: left;
	vertical-align: baseline;
	letter-spacing: -0.6px;
	word-spacing: 0px;
	margin-bottom: 24px;
	line-height: 1.7em;
}
.event-landing  h2{
	margin-top: 20px;
}

.editorLargeText{
	font-size: 125%;
}

.col--dotted{
	padding: 0px 17px 0 0;
	border-right: 1px dotted #ccc;
	box-sizing: border-box;
}

.flex{
	display: -webkit-box !important;
	display: -moz-box !important;
	display: box !important;
	display: -webkit-flex !important;
	display: -moz-flex !important;
	display: -ms-flexbox !important;
	display: flex !important;
}

.issuuembed{
	width: 100%;
	height: 645px;
}


.advert{
	display: block;
	.sidebar1  &{
		margin: 15px 0 30px;
		display: block;
	}
	
	.module_articles &{
		margin-bottom: 15px;
	}
	
	.story &{
		margin-top: 0;
		margin-bottom: 20px;
	}
	
	img{
		max-width: 100%;
		height: auto;
		border: 1px solid #f0f0f0;
		margin: 0 auto;
	}	
}
.accordionContent_sidenav .advert img{
		border:0 none;
}
.content_awards ol{
	margin-left:3em;
}
.content_awards ul{
	margin-left:2em;	
}

.landing__long{
	clear: both;
	padding-top: 8px;
}

.editorLink, .rteLink {
	color: #48BABF;
	font-weight: 600;
}

.ad__newsletter {
	display: block;
	border: 3px solid #846A93;
	color: #846A93;
	padding: 8px;
	text-align: center;
	font-size: 20px;
	line-height: 1.2;
	font-weight: 400;
	text-decoration: none;
	
	&:hover {
		text-decoration: none;
		color: #846A93;
	}
	
	&:first-line {
		font-weight: 600;
	}
}