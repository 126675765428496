@import '/websites/shared/live/scss/node_modules/bourbon/app/assets/stylesheets/bourbon';


//
// Variables
// --------------------------------------------------

// Set debug flag on the preview site for development
$DEBUG: false;

// Layout dimensions
// -------------------------

$wrapper:				1000;

// Typography
// -------------------------
$baseFontSize:          15 !default;
$baseLineHeight:        20 !default;
$baseFontFamily:		Tahoma, Geneva, sans-serif !default;
$secondaryFontFamily: 	'Open Sans', Tahoma, Geneva, sans-serif !default;


// Colors
// -----------------------------------------------------------------------

$purple: 				#886d97;
$purple-2: 				#9158b1;
$blue-2: 				#4a7194; // Search results
$color-1: 				#c6be2f; // Jump Link #1
$color-2: 				#606688; // Jump Link #2
$color-3: 				#e67c54; // Jump Link #3
$color-4: 				#57b59b; // Jump Link #4
$color-5: 				#00647b; // Jump Link #5

// Grays
// -------------------------
$black:                 #000 !default;
$grayDarker:            #222 !default;
$grayDark:              #333 !default;
$gray:                  #555 !default;
$grayLight:             #999 !default;
$grayLighter:           #eee !default;
$white:                 #fff !default;
$greySearch: 			#ddd !default;
$grey-10: 				#ccc !default; 	// sector jump links border
$grey-11: 				#5c5c5c; 		// MORE GREYS!!!

//forms
$formRed: 		rgb(255,0,0);
$formGrey: 		rgb(238,238,238);
$formText: 		rgb(121,121,121);
$formActive: 	#1da1db;
$formGreen: 	green;
$formBG: 		rgba(136,109,151,0.1);


// Scaffolding
// -------------------------
$bodyBackground:        $white !default;
$textColor:             $grayDark !default;


// Hr border color
// -------------------------
$hrBorder:                $grayLighter !default;

// Headings
// -------------------------

$headings-weight:		bold;
$headingsFontFamily: 	Arial, Helvetica, sans-serif;

$h1color:				$textColor;	
$h1weight:				$headings-weight;

$h2color:				$textColor;
$h2weight:				$headings-weight;

$h3color:				$textColor;	
$h3weight:				$headings-weight;

$h4color:				$textColor;	
$h4weight:				$headings-weight;


// Links
// -------------------------
$linkColor:             #08c !default;
$linkColorHover:        darken($linkColor, 15%) !default;


@import 'media-queries.scss';@import 'custom-mixins.scss';@import 'resetcss.scss';@import 'helpercss.scss';@import 'styles-scss.scss';@import 'fancybox-scss.scss';@import 'forms-scss.scss';@import 'cssprint.scss';@import 'flexslider-scss.scss';@import 'layoutcss.scss';@import 'typographycss.scss';@import 'csseditorial.scss';@import 'buttons-scss.scss';@import 'card.scss';@import 'events-landing-scss.scss';@import 'awards-scss.scss';@import 'digital-issues-scss.scss';@import 'product-page-scss.scss';@import 'two-col-scss.scss';@import 'card-grid.scss';@import 'col-block.scss';@import 'home-panels.scss';@import 'sidenav-scss.scss';@import 'temp-mob.scss';@import 'slick-scss.scss';@import 'slider-scss.scss';@import 'gallery-photoswipe.scss';@import 'logo-block.scss';@import 'winner-stuff.scss';@import 'back-scss.scss';@import 'slick-test-scss.scss';@import 'search-scss.scss';@import 'directory-scss.scss';@import 'jump-links-scss.scss';@import 'chosen-scss.scss';
