
// REGISTRATION FORM STUFF //

$module: 'form';

.#{$module} {
	padding: 20px 50px;
	background-color: $formBG;
	font-size: em(12);
	
	ul{
		padding: 0;
	}
	
	&__control {
		display: block;
		margin: 0 0 em(15,12);
		color: $formText;
		text-transform: uppercase;
	}
	
	&__label {
		display: block;
		padding: em(5);
		color: $formText;
		font-size: em(15);
		text-transform: uppercase;
	}
	
	&__error {
		font-weight: 600;
		color: $formRed;
	}
	
	&__input {
		display: block;
		width: 100%;
		height: em(38);
		margin-bottom: em(5);
		border-radius: 4px;
		border: 1px solid $formGrey;
		border-bottom: 2px solid $formRed;
		
		&:active, &:focus { 
			border-bottom: 2px solid $formActive;
			outline: 0;
		}
		
		&:valid {
			border-bottom: 2px solid $formGreen;
		}
	}
	
	&__checkboxes {
		> li {
			width: 25%;
			display: inline-block;
			@include bp(mobile) {
				width: 100%;
			}
		}
	}
	
	&__control--cb {
		@extend .#{$module}__control;
		display: flex;
	}
	
	&__checkbox {
		margin: em(6,11) 0 0 0;
		width: em(21,11);
	}
	
	&__textarea {
		display: block;
		width: 100%;
		min-height: em(100);
		border: 1px solid $formGrey;
		border-bottom: 2px solid $formRed;
		border-radius: 4px;
		
		&:active, &:focus {
			border-bottom: 2px solid $formActive;
			outline: 0;
		}
		
		&:valid {
			border-bottom: 2px solid $formGreen;
		}
	}
	
	&__select {
		border: 1px solid darkgray;
		outline: medium none;
		height: 30px;
		min-width: 300px;
		}
	
}

.g-recaptcha {
	margin-bottom: em(15);	
}