

$module: slick;

.#{$module} {
	
	&__thumbnails {
		margin-bottom: em(25);
		
		.slick-track {
			height: em(100);
		}
		
		.slick-prev, .slick-next {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			background-color: transparent;
			color: transparent;
			border: none;
			
			&:focus {
				outline: none;
			}
			
			&:before {
				content: "";
				display: block;
				height: em(36,11);
				width: em(24,11);
				background-repeat: no-repeat;
				position: absolute;
				cursor: pointer;
			}
		}
	
		.slick-prev {
			left: -20px;
			
			&:before {
				background-image: url('/media/images/arrow-left-media_2566.gif');
			}
		}
		
		.slick-next {
			right: 0;
			
			&:before {
				background-image: url('/media/images/arrow-right-media_2565.gif');
			}
		}
	}
	
	&__thumbnail {
		background-repeat: no-repeat;
		background-size: cover !important;
		width: 130px;
		margin-right: em(15,11);
		background-position: center !important;
	}
}