

$module: 'issues';

.#{$module} {
	
	&__wrapper {
		@include display(flex);
		@include flex-direction(row);
		@include flex-wrap(wrap);
		@include align-items(baseline);
	}
	
}