

$module: 'two-col';

.#{$module} {
	display: flex;
	gap: 2rem;
	margin-bottom: 1rem;
	
	@media(max-width: #{$wrapper}px) {
		flex-direction: column;
	}
	
	&__main-content {
		flex-basis: 66.6%;
	}
	
	&__side-content {
		flex-basis: 33.3%;
	}
	
	&__side-content--left {
		@extend .#{$module}__side-content;
		order: -1;
	}
}