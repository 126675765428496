

$module: directory;

.#{$module} {
	
	&__banner {
		display: block;
		margin: em(20) auto;
	}
	
	&__copy {
		padding: em(20) em(100);
	}
	
	&__nav {
		position: relative;
		z-index: 1;
		
		& .mega_box {
			@include bp(mobile) {
				max-height: 100% !important;
				overflow: visible !important;
				
			}
		}
	}
}