

$module: 'product';

.#{$module} {
	padding: em(30) 0 0 0;
	
	&__left, &__right {
		display: inline-block;
		vertical-align: top;
	}
	
	&__left {
		width: 70%;
	}
	
	&__right {
		width: 30%;
		padding: em(70) 0 0 0;
	}
	
	&__media-container {
		max-width: 100%;
		padding: 0 em(25) 0 0;
	}
	
	&__heading {
		$fontSize: 30;
		font-size: em($fontSize,16);
		font-weight: bold;
		font-family: 'Times New Roman', serif;
		padding: em(20,$fontSize) 0;
	}
	
	&__copy {
		$fontSize: 16;
		font-size: em($fontSize);
		line-height: 1.6;
		font-family: 'Times New Roman', serif;
	}
	
	&__blockquote-container {
		padding: em(15) em(25) 0 0;
 		text-align: left;
 		max-width: em(200);
	}
	
	&__blockquote-container--bottom {
		@extend .#{$module}__blockquote-container;
		max-width: none;
	}
	
	&__blockquote-top {
		$fontSize: 24;
		font-size: em($fontSize,16);
	    font-family: 'Times New Roman', serif;
	    font-style: italic;
	    color: $purple;
	    margin: 0 0 em(10,$fontSize) 0;
	    
	    .#{$module}__blockquote-container--bottom & {
	    	$fontSize: 28;
	    	font-size: em($fontSize,16);
	    	padding: 0;
	    }
	}
	
	&__blockquote-bottom {
	    $fontSize: 12;
		font-size: em($fontSize,16);
	    
	    .#{$module}__blockquote-container--bottom & {
	    	$fontSize: 14;
	    	font-size: em($fontSize,16);
	    }
	}
	
	&__blockquote-name {
		font-weight: bold;
	}
	
	&__blockquote-job {
		font-weight: 100;
	}
	
	&__shopify-container {
		text-align: center;
		padding: em(130) 0 0 0;
	}
	
}