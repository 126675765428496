

.sidenav{
	
	&--country{
		background: #4a636b;
		padding: 5px;
		margin-bottom: 20px;
	}
	
	&__item{
		background: #fff;
		border-bottom: 1px solid #efeff0;
		width: 100%;
		height: 1.7em;
		line-height: 1.7em;
		text-indent: 1.3em;
		display: block;
		position: relative;
		font-size: 0.923em;
		font-weight: 200;
		color: #878d95;
		
		.current &{
			color: #4A7194;
		}
	}
	
	&__title{
		background-color: #886d97;
		@include linear-gradient(top, rgb(136, 109, 151), rgb(82, 61, 90));
		border-bottom: 1px solid #886d97;
		box-shadow: inset 0px 1px 0px 0px #886d97;
		width: 100%;
		height: 2em;
		line-height: 2em;
		text-indent: 1em;
		position: relative;
		font-weight: 600;
		text-transform: uppercase;
		font-size: 16px;
		color: #fff;
		text-shadow: 0px 1px 0px rgba(0,0,0,.5);
	}
}