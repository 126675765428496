
.home-panels{
	display: grid;
	grid-auto-flow: column;
	grid-auto-columns: 1fr;
	gap: 20px;
	margin: 20px 0 0;
	
	@media (max-width: 600px) {
		grid-auto-flow: initial;
	}
}

.home-panel{
	height: 200px;
	display: flex;
	align-items: center;
	padding: 0 44px;
	background-size: cover;
}

.home-panel__title{
	text-align: center;
	font-size: 32px;
	line-height: (38/32);
	color: white;
	font-family: Helvetica, serif;
	font-weight: bold;
}