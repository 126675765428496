
//
// Typography
// --------------------------------------------------

.editorBullets {
	padding-left: 0;
	margin-left: 0;
	font-family: sans-serif;
	font-size: 12px;
	line-height: 1.8em;
	vertical-align: baseline;
	letter-spacing: normal;
	word-spacing: 0px;
	
	li { 
		background:url(/media/images/bullet-arrow_20119.png) no-repeat 0 6px;
		padding-left: 18px;
	}
	
	ol > li {
		background: none;
		padding-left: 0;
	}
}