

.slider.slider{
	padding: 0;
}

.slider__wrapper{
	padding-top: 14px;
	margin-bottom: 15px;
	position: relative;
}


.slick-slide{
	position: relative;
}

.slide__link{
	display: block;
}

.slide__image{
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	width: auto;
	min-height: 100%;
    background-size: contain;
    background-position: top;
    background-repeat: no-repeat;
}

.slide__imagecont{
	padding-bottom: 64.5%;
	width: 100%;
	overflow: hidden;
	position: relative;
}

.slide__prev,.slide__next{
	position: absolute;
	top: 44%;
	margin-top: -18px;
	z-index: 5;
	cursor: pointer;
}

.slide__prev{
	left: 0;
}
.slide__next{
	right: 0;
}

.slide__box{
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
}

#slide-runner .slide {
	width: 100%;
}


/// facebook slick slider styles

.slick__wrapper {
	
	.gallery__thumbnail {
		background-size: contain;
	}
}