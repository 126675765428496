
@media print {
  /*Reset Style*/
	body {margin:0; padding:0; line-height: 1.4em; word-spacing:1px; letter-spacing:0.2px; font: 13px Arial, Helvetica,"Lucida Grande", serif; color: #000;}
	/*Remove Element*/
	#logo, #catnavi, .topnavi, .more-link, .navigation, #sidebartop, #related, #social, #sponsors, .tabs, #allpost, .toolbar, .splitbox, #commentform, #commentabs .idTabs, .postmeta-content .comments, #respond h3, .tag, .footerlinks {display:none;}
	
	a:link, a:visited {background: transparent; color:#333; text-decoration:none;}
	a:link[href^="http://"]:after, a[href^="http://"]:visited:after {content: " (" attr(href) ") "; font-size: 11px;}
	a[href^="http://"] {color:#000;} 
	
	/*Break Page*/
	#comments {page-break-before: always;}
}