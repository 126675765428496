

$module:  	btn;

.#{$module}{
	display: inline-block;
	margin-bottom: 0;
	font-weight: normal;
	text-align: center;
	vertical-align: middle;
  	touch-action: manipulation;
	cursor: pointer;
	background-image: none;
	border: 1px solid transparent;
	white-space: nowrap;
	@include user-select(none);
	line-height: normal;
	
	&:hover,
	&:focus{
		text-decoration: none;
	}
	
	&:active{
		outline: 0;
    	background-image: none;
	}
	
	&--primary{
		@extend .#{$module};
		border: 1px solid darkgray;
		padding: 10px 14px 11px;
		font-size: 16px;
		
		.form__control & {
			//display: block;
			//margin: 0 auto;
			border-radius: 4px;
			border: 1px solid $formGrey;
			border-bottom: 2px solid $formRed;
			color: $formText;
			background-color: $white;
			
			&:hover {
				border-bottom: 2px solid $formActive;
				transition: 0.2s ease-in;
			}
		}
	}
	

}