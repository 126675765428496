

$module: 		'back';

.#{$module} {
	&__list{
		margin-bottom: 10px;
		text-align: right;
	}
	
	&__item{
		font-size: 12px;
	}
}