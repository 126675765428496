

$module: 		'winner';

.#{$module} {
	&__title {
		padding-top: 60px !important;
	}
	&__sponsor {
		font-weight: 400;
		padding: 0;
		color: black;
		display: block;
	}
	&__subtitle {
		font-weight: 700 !important;
		padding: 0 !important;
		color: black !important;
	}
	&__shortlist-items {
	}
	&__shortlist-item {
		font-weight: 300;
		color: $black;
	}
	&__link {
	}	
}