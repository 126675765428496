

$module: 'card-grid';

.#{$module} {
	display: grid;
  	grid-template-columns: repeat(auto-fill, minmax(228px, 1fr));
  	gap: 1rem;
  	
  	&__item {
  		display: contents;
  	}
}