

$module: 'col-block';

.#{$module} {
	--background-color: none;
	background-color: var(--background-color);
	height: 100%;
	padding: 2rem;
	text-align: center;
	box-sizing: border-box;
}