


// awards copy //

$module: 				content_awards;
$linkColour: 			#dca000;
$darkPurple: 			#5E367C;
$blue: 					#067ab0;
$gray: 					#F5F5F5;

.#{$module} {
	
	font-size: 15.5px;
	font-family: Arial;
	font-weight: 400;
	line-height: 26px;
	padding-top: 30px;
	margin-top: 0;
	
	a{
		color: #4A7194;
		font-weight: bold;
		width:150px
	}
	
	h2:not(.section_header) {
		font-family: 'Open Sans', sans-serif;
		font-size: 20px !important;
		font-weight: 600;
		padding: 10px 0;
		line-height: 1.4em;
		margin: 15px 0 0;
		color: $darkPurple;
		font-weight: 600;
		font-size: 22px;
	}
	
	.logo-block__item {
		padding-bottom: 15%
	}
	
	.editorBullets li{
		background:url(/media/images/bullet-arrow_20119.png) no-repeat 0 6px;
		margin-bottom: 14px;
		padding-left: 18px;
	}
	
	.btn_teal{
		color: white;
	}
	
	p {
		font-size: 15.5px;
		font-family: Arial;
		font-weight: 400;
		line-height: 26px;
	}
	
	&_header {
		width: auto;
		margin-top: em(14);
	}
	
	.awards__title {
		font-weight: 600;
		font-size: 28px;
		margin: -17px 0 15px;
		padding-bottom: 12px;
		border-bottom: solid 1px #f7f7f7;
		font-family: 'Open Sans', sans-serif;
		text-align: left;
		line-height: 1.3em;
		vertical-align: baseline;
		letter-spacing: -0.6px;
		word-spacing: 0px;
	}
	
	.editorLink {
		color: #48BABF;
		font-weight: 600;
	}	
	
	.emailLink {
		color: #48BABF;
		font-weight: 600;
	}
	
	.editorBullets,.editorList > li {
		font-size: 15.5px;
	}
	
	.editorBullets,.editorList:not(ol) { 
		> li {
			background: none;
			background-image: url(/media/images/arrowgold_3467.png);
			background-repeat: no-repeat;
			background-position: 0 6px;
			padding-left: 25px;
		}
	}
}



// quote //

$quoteBG: 				#F5F5F5;
$module: 				editorQuote;

.#{$module} {
	&-wrap {
		margin-top: em(20);
		background-color: $gray;
		padding: 30px 105px 30px 60px;
	}
	&__copy {
		color: $darkPurple;
		font-family: Arial;
		font-style: italic;
		font-weight: 300;
		font-size: 15.5px;
		line-height: 26px;
		position: relative;
		max-width: 490px;
		display: inline;
		
		&:before {
			position: absolute;
			content: '\201C';
			top: -5px;
			left: -38px;
			font-family: Open Sans,sans-serif;
			font-size: 65px;
			font-weight: 600;
			font-style: normal;
			color: $darkPurple;
		}
		&:after {
			position: absolute;
			content: '\201D';
			bottom: -25px;
			right: -35px;
			font-family: Open Sans,sans-serif;
			font-size: 65px;
			font-weight: 600;
			font-style: normal;
			color: $darkPurple;
		}
	}
	&__author-details {
		padding-top: 15px;
		padding-bottom: 8px;
	}
	&__author-name {
		font-family: Arial;
		font-weight: 300;
		display: inline-block;
		padding-right: 10px;
		font-size: 15.5px;
		color: $black;
	}
	&__author-info {
		font-weight: 300;
		font-family: Arial;
		display: inline-block;
		font-size: 15.5px;
		color: $black;
	}
	&__award {
		display: block;
		font-family: Arial;
		font-weight: 600;
		font-size: 15.5px;
		color: $black;
		line-height: 1;
	}
}

.box_border_thick {
	border-color: $darkPurple;
}

.awards_grey_box{
	display: block;
	background-color: #f4f4f5;
	margin: 20px 0;
	padding: 20px 25px;
	font-size: 14px;
	font-family: 'Open Sans', sans-serif;
	
	a{
		color: #49176d;
		font-weight: 600;
	}

	.editorLargeText{
		color: #49176d;
		font-weight: 600;
	}
}