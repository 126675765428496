

//
// Media Queries
// --------------------------------------------------

@mixin bp($point) {
	// Mobile  37.5em = 600px
  	@if $point == mobile {
		@media (max-width: 600/16+em ) { @content; }
	}

}