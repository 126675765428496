

$module: 'search-results';

.#{$module} {
	position: relative;
	width: 670px;
	margin: 19px 0 0 0;
	padding: 0px 19px 0 0;
	border-right: 1px dotted #e6e6e6;
	float: left;
	
	@include bp(mobile) {
		width: 100%;
		padding: 20px 0 0 0;
		margin: 0;
		float: none;
	}
	
	&__top {
		background-color: $greySearch;
		padding: em(15) 0 em(15) em(35);
		margin: 0 0 em(30) 0;
	}
	
	&__heading {
		margin: 0 em(30,16) 0 0;
		position: relative;
		
		&:after {
			content: '';
			position: absolute;
			display: block;
			width: 2px;
			top: 0;
			bottom: 0;
			right: em(-30,16);
			@include linear-gradient(90deg, $greySearch 0%, $greySearch 15%, white 16%, white 85%, $greySearch 86%, $greySearch 100%);
		}
	}
	
	&__search {
		padding: 0 0 0 em(30);
		color: $purple-2;
	}
	
	&__bottom {}
	
	&__item {
		margin: 0 0 em(30,16) 0;
		padding: 0 0 em(30,16) 0;
		border-bottom: 1px solid rgba(black,0.09);
		display: block;
	}
	
	&__image {
		display: inline-block;
		margin: 0 em(20) 0 0;
		vertical-align: top;
		
		@include bp(mobile) {
			max-width: 26%;
		}
	}
	
	&__item-inner {
		display: inline-block;
		width: 70%;
		vertical-align: top;
		color: $blue-2;
	}
	
	&__title {
		$fontSize: 13;
		font-size: em($fontSize);
		margin: 0 0 em(25,$fontSize) 0;
		text-transform: uppercase;
		font-family: 'Open Sans', sans-serif;
		font-weight: 600;
	}
	
	&__copy {
		$fontSize: 13;
		font-size: em($fontSize);
		font-family: 'Arial Bold', sans-serif;
		margin: 0;
		line-height: (17.5/$fontSize);
		font-weight: 500;
	}
	
	&__page {
		@include clearfix;
		> ul {
			padding: 0 em(20) 0 0;
			display: inline-block;
			float: right;
			position: relative;
		}
		
		li {
			display: inline-block;
		}
	}
	
	&__page-item {
		position: relative;
		margin: 0 em(4);
		width: em(25);
		max-height: em(26);
		min-height: em(26);
		display: block;
		text-align: center;
		
		&.active {
			background-color: $blue-2;
			color: white;
		}
	}
	
	&__page-item-inner {
		position: absolute;
		top: 50%;
		@include transform(translateY(-50%));
		left: 0;
		right: 0;
		margin: 0 auto;
	}
	
	&__arrow {
		height: em(24);
		width: em(25);
		border: 1px solid $blue-2;
		position: relative;
		display: block;
		
		&:before, &:after {
			content: '';
			position: absolute;
			display: block;
			width: em(1);
			height: em(10);
			background-color: $blue-2;
			top: 50%;
			left: 0;
			right: 0;
			margin: 0 auto;
		}
		
		&:before {
			@include transform(rotate(40deg)translateY(-50%));
		}
		
		&:after {
			@include transform(rotate(-40deg)translateY(-50%));
		}
	}
	
	&__arrow--left {
		@extend .#{$module}__arrow;
		@include transform(rotate(90deg));
		
		&.inactive {
			border: 1px solid #bebebe;
			
			&:before, &:after {
				background-color: #bebebe;
			}
		}
	}
	
	&__arrow--right {
		@extend .#{$module}__arrow;
		@include transform(rotate(-90deg));
		
		&.inactive {
			border: 1px solid #bebebe;
			
			&:before, &:after {
				background-color: #bebebe;
			}
		}
	}
}