

.event__list.event__list{
	padding-left: 0;
	padding-bottom: 0px;
	margin-top: -10px;
}

.event ul {
	list-style-type: disc;
	font-size: 12px;
	font-family: sans-serif;	
}

.event__item{
	display: block;
	   padding: 5px 0px;
    border-bottom: 1px solid #EDEAEA;
}

.event__date{
display: inline-block;
width: 20%;
font-weight: bold;
vertical-align: middle;
text-align: center;
padding: 10px 18px;
background: rgba(237, 237, 237, 1);
margin: 0px 9px 0px 0px;
	}
	
.event__title{
display: inline-block;
width: 70%;
vertical-align: middle;
	}