

.advert {
	margin: 15px 0;
}

.footer_top {
	margin-top: 0;
}

#search {
	&:focus {
		border: none !important;
		outline: none;
	}
}

.big_slider_ul li img {
	height: 265px;
	width: 482px;
}

.copyright-info {
	font-size: 10px;
}

.form-link {
	text-transform: uppercase;
	font-family: Open Sans, sans-serif;
	font-size: 12px;
	font-weight: 400;
	line-height: 24px;
	border-left: 1px solid #CCC;
	text-decoration: none;
	float: none;
	display: inline-block;
	background-color: #886D97;
	color: #FFFFFF;
	padding: 6px 10px;
	
	&:hover {
		box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.08) inset;
		text-decoration: none;
		color: $black;
	}
}

.js-menu {
	display: none !important;
}

.awards_right {
	float: right;
}

.content_awards_header {
	float: none;
}

.content_awards {
	float: none;
}

.issuuembed {
	height: 645px;
}

.qklink_right_scroll {
	z-index: 9;
	position: relative;
}

@media (max-width: 600px) {
	
	//GENERAL//
	
	body {
		overflow-x: hidden;
	}
	
	.content {
		margin-top: 20px;
	}
	
	.home_content2 {
		border-right: 0;
	}
	
	.wrap {
		width: auto;
		margin: 0 auto;
		padding-left: 10px;
		padding-right: 10px;
	}
	
	#top_ad_area {
		a {
			img {
				margin: 0 auto;
				max-width: 100%;
			}
		}
	}
	
	.header_moving_pos {
		max-width: 100%;
		&:before {
			content: "";
			display: table;
		}
		&:after {
			clear: both;
			content: "";
			display: table;
		}
	}
	
	.wrap_content {
		max-width: 100%;
	}
	
	.content {
		max-width: 100%;
		padding: 0;
		margin: 0 auto;
		float: none !important;
		display: block;
		border: 0;
	}
	
	#story_image {
		max-width: 100%;
		height: auto;
		
		img {
			width: 100%;
		}
	}
	
	.sidebar1 {
		display: block;
		float: none !important;
		width: auto;
		max-width: 100%;
	}
	
	//HOME//
	
	.home_content2 {
		float: none !important;
		max-width: 100%;
		padding: 0;
	}
	
	.col {
		width: 100%;
		border: none;
		padding: 0 !important;
		margin: 0 !important;
		background-color: white !important;
	}
	
	.no_margin {
		img {
			margin: 0 auto;
		}
	}
	
	/*.big_slider_inner {
		display: none;
	}*/
	
	.span_2_of_3 img{
		margin: 0 auto;
	}
	
	.big_slide_inner {
		display: none;
	}
	
	//COUNTRY//
	
	#breadcrumb_main_container {
		max-width: 100%;
	}
	
	// FOOTER //

	.col_ftr {
		width: auto;
		margin: 0;
		float: none;
	}
	
	.ftr_inner_colspan-block {
		display: inline-block;
		float: none;
		width: 50%;
		vertical-align: top;
	}
	.ftr_inner_colspan_bdr {
		display: inline-block;
		float: none;
		width: 50%;
		vertical-align: top;
	}

	
	/// HEADER //
	
	.logo_main {
		float:  none;
		display:  block;
		margin: 0 auto 20px;
	}
	
	.logo_main_strap_box {
		position:  relative;
		text-align:  center;
		display:  block;
		margin:  0;
		float:  none;
		height:  auto;
	}
	
	.logo_main_strap_left {
		display:  none;
	}
	
	.logo_main_strap_right {
		display:  none;
	}
	
	.logo_main_strap_mid {
		position:  relative;
		font:  12px 'Open Sans', sans-serif;
		font-family:  'Open Sans', sans-serif;
		color:  #242424;
		font-weight:  400;
		font-style:  italic;
		text-align:  left !important;
		display:  inline-block;
		text-align:  left;
		float:  none;
		padding:  0;
		height:  auto;
		padding: 0 8px 0 0;
	}
	
	.logo_main_strap_mid::before{
		position:  absolute;
		width:  22px;
		height:  68px;
		background-image:  url(/media/images/bracket-purple-left_2403.gif);
		left:  -22px;
		top:  50%;
		margin-top:  -34px;
		content:  "";
	}
	
	.logo_main_strap_mid::after{
		position:  absolute;
		width:  22px;
		height:  68px;
		background-image:  url(/media/images/bracket-purple-right_2402.gif);
		right:  -15px;
		top:  50%;
		margin-top:  -34px;
		content:  "";
	}
	
	// BACKGROUND //
	
	.span_1_of_2_topmod_lft {
		background:  white !important;
	}
	
	// SEARCH //
	
	#resultsContainer {
		position: relative;
		z-index: 99999;
		display: block;
	}
	
	#results_text {
		margin: 0;
		top: auto;
		width: 100%;
		padding: 0;
		height: auto;
		
		span {
			line-height: 50px;
			padding-left: 25px;
		}
	}
	.cross_box {
		float: none;
		margin-top: -7px;
		position: absolute;
		top: 50%;
		right: 10px;
	}
	ul#results {
		position: absolute;
		background-color: #fff;
		position: absolute;
		list-style: none;
		z-index: 1000 !important;
		width: auto;
		top: 50px;
		left: 0px;
		max-height: 340px;
		overflow-y: scroll;
		margin: 0;
		padding: 0;
	}
	
	// DIRECTORY //
	
	.content_full_width {
		width:  auto;
	}
	
	.directory_featured_text {
		width:  auto;
		height: auto;
		float: none;
		padding: 15px 0 0;
	}
	
	#directory_expanded_col2 {
		float: none;
		width: auto;
		border: none;
	}
	
	#directory_expanded_text {
		padding: 0;
		float: none;
	}
	
	.directory_featured_logo {
		float: none !important;
		border: 0;	
	}
	
	.filter_strip_block {
		height: auto;
		padding: 6px 12px 6px 12px;
		
		select {
			display: block;
			margin: 0 auto;
		}
	}
	
	.filter_strip_lbl {
		padding-bottom: 5px;
		display: block;
		text-align: center;
		margin: 0 auto;
	}
	
	// AWARDS //
	
	.box_border_thick {
		display: block;
	}
	
	.advert {
		display: block;
		margin: 0 auto;
	
		img {
			margin: 0 auto;
		}
	}
	
	.sidenav {
		width: auto;
		float: none !important;
		padding: 0;
	}
	
	.sidenav_title_sel {
		width: auto;
	}	
	
	.sidenav_list {		
		width: auto !important;
		li {
			a {
				width: auto;
				margin-left: 0;
			}
		}
	}
	
	.content_awards_header {
		/*width: auto;
		float: none;
		overflow-x: hidden;
		border: 0;*/
		
		display: none;
		
		img {
			display: block;
			margin: 0 auto;
			width: 415px;
			height: 98px;
		}
	}
	
	.content_awards {
		width: auto;
		float: none;
		padding: 0;
		border: 0;
		min-height: initial;
	}
	
	.awards_right {
		padding-top: 30px;
		float: none;
	}
	
	// NAVIGATION //
	

	.nav_item {
		border: 0;
		
		.mega_col_a_sub {
			background-color: transparent !important;
		}
		.mega_col_a_main {
			background-color: transparent !important;
		}
		.mega_col_a_genrl {
			background-color: transparent !important;
			margin: 5px 0 !important;
		}
		
	}
	
	.main_nav_a{
		text-align: left;
	}
	
	.mega_col_h3 {
		font-size: 16px;
		line-height: 1.2;
		margin: 0;
		padding: 5px 20px;
	}
	
	.mega_box {
		@include flex-wrap(wrap);
		width: auto !important;
		position: static !important;
		max-height: 0 !important;
		overflow: hidden !important;
		visibility: visible !important;
		border: 0 !important;
		margin: 0 !important;
		opacity: 1 !important;
		background-color: #F0F0F0 !important;
		@include transition(all 0.8s ease-in);
		
		.open & {
			max-height: 2000px !important;
			padding: 5px 0 !important;
		}
	}

	.mega_column {
		float: none;
		display: block;
		width: auto;
		margin: 0 !important;
		border-right: 0 !important;
	}
	
	.mega_col_a_main {
		width: auto;
		margin: 0 !important;
		border: 0 !important;
	}
	
	.nav_menu {
		float: none;
		display: block;
		width: auto;
		border: 0;
		cursor: pointer !important;
		width: 100%;
		
		.mega_col_a_sub {
			background-color: transparent !important;
		}
		.mega_col_a_main {
			background-color: transparent !important;
		}
		.mega_col_a_genrl {
			background-color: transparent !important;
			margin: 5px 0 !important;
		}
		
		a {
	    
		    &:hover {
		    	background-color: #4B4441 !important;
				background-position: right -55px !important;
				cursor: pointer;
				color: #FFF !important;
		    }
		}
	}
	
	.mega_box_half {
		float: none;
		width: auto;
		border: 0 !important;
	}
	
	.has_hover {
		.open & {
			background-color: #4B4441 !important;
			background-position: right -55px !important;
			cursor: pointer;
			color: #FFF !important;
		}
	}
	
	.js-menu {
		display: block !important;
	}	
	
	.nav {
		position: relative;
		overflow: hidden;
		max-height: 61px;
		height: auto;
		@include transition(max-height 0.8s ease-out);
		margin-top: 10px;
		flex-direction: column;
	}
	
	
	.active {
		max-height: 5000px;
	}
	
	.big_grey_box {
		@include flex-wrap(wrap);
		@include justify-content(center);
		background-color: #FFF;
	}
	
	.col {
		float: none;
	}
	
	.issuuembed {
		height: 300px;
	}
	
	.magazine {
		margin-right: 0;
		display: block;
	}
	
	.logo_main {
		background-repeat: no-repeat;
	}
	
	.content {
		margin-top: 20px !important;
	}
	
	#searchForm {
		float: none;
		display: block;
		width: auto;
		border: 0;
		
		fieldset {
			width: auto;
		}
		
		.input {
			float: none;
			width: auto;
		}
		
		#search {
			width: 100%;
			
			&:focus {
				border: none;
			}
		}
	}
	
	#searchForm--secondary {
		display: inline-block;
		width: 26%;
	}
	
	.social_media_cont {
		display: block;
		text-align: center;
		padding-top: 5px;
		
		&:before, &:after {
			content: none;
		}
	}
	
	.form-link {
		margin-right: 10px;
		margin-bottom: 10px;
	}
	
	.social_media_cont .roundel_social {
		float: none;
		display: inline-block;
	}
	
	// Slider
	
	.slider{ 
		.hdlne_big_catch_text{
			display: none;
		}
		.hdlne_big{
			font-size: 15px;
			display: block;
		}
	}
	
	.editorLeftImage, .editorRightImage {
		float: none !important;
		text-align: center !important;
		margin: 0 auto;
	}
	
	.directory_standard_text{
		margin-left: 0;
		padding-left: 0;
	}
	
	.directory_standard_logo{
		float: none;
		display: block;
	}
	
	// new header
	
	.account_strip {
		margin-bottom: 10px;
	}
	#top_ad_area {
		float: none;
		margin: 0 auto;
		padding: 0;
	}
	#results_text {
		text-align: left;
		padding: 15px;
		top: 0;
		left: 0;
		right: 0;
		width: auto;
		
		span {
			padding: 0;
			line-height: 1.1;
			display: inline-block;
		}
	}

}