

$module: 			logo-block;

.#{$module} {
	&__title {
		font-size: em(40) !important;
		line-height: 1.1;
		&:after {
			display: block;
			content: "";
			background-color: #5E367C;
			width: 100%;
			height: 2px;
		}
		@media(max-width: 700px) {
			font-size: em(32) !important;
		}
	}
	&__items {
		margin: 0 auto;
		text-align: left;
	}
	&__item {
		width: 32%;
		margin-left: 1%;
		padding-bottom: 20%;
		display: inline-block;
		vertical-align: middle;
		position: relative;
	}
	&__anchor {
		background-repeat: no-repeat;
		background-position: center center;
		background-size: 100% auto;
		position: absolute;
		left: 0; right: 0; top: 0; bottom: 0;
	}
}

.awards {
	&__endorser {
		width: 48%;
		height: em(50);
		display: inline-block;
		margin: 1%;
		vertical-align: middle;
	}
}